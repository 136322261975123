import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../common/axiosInstance";
import axios from "axios";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { Trash3, ArrowClockwise, CloudArrowUpFill, EyeFill } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { _limits, _security } from "../../common/constants";
import { checkTokenExpiry } from "../../common/utils";
import { format } from "date-fns";

function PromoteFiles() {
  useEffect(() => {
    checkTokenExpiry();
    getUser();
  }, []);

  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [showAllocatedBudgetInfo, setShowAllocatedBudgetInfo] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [embeddedURL, setEmbeddedURL] = useState("");
  const [selectedDBFile, setSelectedDBFile] = useState(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [allocatedBudget, setAllocatedBudget] = useState(_limits.advDefaultAllocatedBudget);
  const [totalBudget, setTotalBudget] = useState(0);
  const [fileExt, setFileExt] = useState("");
  const [deleteShow, setdeleteShow] = useState(false);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check File Size
    const maxSize = _limits.videoSize * 1024 * 1024;
    if (file.size > maxSize) {
      notifyError(
        `File size exceeds the maximum limit (${_limits.videoSize} MB).`
      );
      return;
    }
    // Check file extension
    const allowedExtensions = [
      "webp",
      "mp4",
      "webm",
      // "aac",
      // "wav",
      // "mp3",
      // "mpeg",
    ];
    const fileExtension = file.name.slice(
      ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
    );
    setFileExt(fileExtension);
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      notifyError(
        `Invalid file extension. Allowed extensions are .webp, .mp4 and .webm`
      );
      return;
    }
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64 = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEmbeddedLinkChange = (e) => {
    setEmbeddedURL(e.target.value);
  };

  // Clear the selected file
  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/user?username=${userInfo.username}`)
        .then((response) => {
          let user = response.data.user;
          if (user) {
            if (!user.isAdvertisementAgreement) navigate('/lobby');
          } else {
            navigate('/lobby');
          }
          setLoading(false);
        })
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getAdvertisements = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance
        .get(`/advertisements?username=${userInfo.username}`)
        .then((response) => {
          let advertisements = response.data.advertisements;
          setAdvertisements(advertisements);
          setTotalBudget(Number(advertisements.reduce((sum, adv) => Number(sum) + Number(adv.allocatedBudget), 0)));
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const saveFile = async () => {
    if (!selectedFile) {
      notifyError("please upload file");
      return;
    }
    if (allocatedBudget < _limits.advDefaultAllocatedBudget) {
      notifyError("The minimum allocated budget is $" + _limits.advDefaultAllocatedBudget);
      return;
    }
    setLoading(true);
    // Request a signed URL from the server
    const response = await axiosInstance.post("requestuploadurl", {
      username: userInfo.username,
      type: fileExt,
    });
    const { key, signedUrl } = response.data.data;

    await axios
      .put(signedUrl, selectedFile, {
        headers: { "Content-Type": selectedFile.type },
      })
      .then(async () => {
        const requestModal = {
          username: userInfo.username,
          key: key,
          fileName: selectedFile.name,
          fileType: selectedFile.type,
          fileSize: selectedFile.size,
          allocatedBudget: allocatedBudget,
          totalBudget: Number(totalBudget) + Number(allocatedBudget)
        };
        await axiosInstance
          .post("/upload", requestModal)
          .then((response) => {
            setLoading(false);
            notifySuccess(response.data.message);
            clearSelectedFile();
            setAllocatedBudget(_limits.advDefaultAllocatedBudget);
            setTimeout(() => {
              getAdvertisements();
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 403) {
              notifyError(error.response.data.message);
            }
          });
      })
      .catch((error) => {
        notifyError("Error uploading file to S3");
        setLoading(false);
      });
  };

  const getFile = async (s3FileKey) => {
    setLoading(true);

    const requestModal = {
      username: userInfo.username,
      s3FileKey: s3FileKey,
    };

    try {
      const response = await axiosInstance
        .post("/file", requestModal)
        .then((response) => {
          let url = response.data.signedUrl;
          setLoading(false);
          window.open(url, "_blank");
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const updateExpiredTime = async (s3FileKey, advertisementId) => {
    setLoading(true);

    const requestModal = {
      advertisementId: advertisementId,
      username: userInfo.username,
      s3FileKey: s3FileKey,
    };

    try {
      const response = await axiosInstance
        .post("/fileExpiration", requestModal)
        .then((response) => {
          getAdvertisements();
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const deleteFile = async (s3bucketId, s3FileKey) => {
    const requestModal = {
      username: userInfo.username,
      s3bucketId: s3bucketId,
      s3FileKey: s3FileKey,
    };

    try {
      setdeleteShow(false);
      setLoading(true);
      const response = await axiosInstance
        .post("/deletefile", requestModal)
        .then((res) => {
          getAdvertisements();
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const handleDeleteFile = (file) => {
    setSelectedDBFile(file);
    setdeleteShow(true);
  };

  const handleAllocatedBudget = (e) => {
    setAllocatedBudget(e.target.value);
  };

  const handleIsAdvertise = async (e, adv) => {
    const requestModal = {
      advId: adv.id,
      username: userInfo.username,
      status: e.target.checked
    }
    updateAdvStatus(requestModal);
    setAdvertisements((prev) =>
      prev.map((advertisement) =>
        advertisement.id === adv.id ? { ...advertisement, isAdvertised: !advertisement.isAdvertised } : advertisement
      )
    );
  }

  const updateAdvStatus = async (requestModal) => {
    try {
      const response = await axiosInstance.post("/advstatus", requestModal)
        .then((res) => {
          notifySuccess(response.message);
        })
    } catch (error) {
    }
  };

  const upgradePackage = () => {
    navigate('/upgrade-package')
  };

  useEffect(() => {
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setSelectedFileURL(fileURL);
    }
  }, [selectedFile]);

  useEffect(() => {
    getAdvertisements();
  }, []);

  return (
    <>
      {/*Allocated Budget Info Modal */}
      <Modal
        size="md"
        show={showAllocatedBudgetInfo}
        onHide={() => setShowAllocatedBudgetInfo(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Maximize Your Reach!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="small light-cyan my-3">
            <ul>
              <li>While budgeting is essential, it does not secure preferential ad placement with OPIC*Verse platform where millions of people gather on private and public rooms.</li>
              <li>Ensure your brand stands out by joining the top-tier Premium Advertisers Club. Paying to be part of this elite group guarantees your advertisement the spotlight it deserves! Ensure your brand stands out by joining the top-tier Premium Advertisers Club. Paying to be part of this elite group guarantees your advertisement the spotlight it deserves!</li>
            </ul>
          </p>
          {/* <p className="light-cyan">Your budgeted amount does not guarantee that OPIC will preferably play your video advertisement.</p> */}
          <div className="d-flex justify-content-end align-items-center gap-3">
            <button
              className="gradient-btn mt-4 px-3 py-2"
              data="Pay to be part of Elite Group"
              onClick={upgradePackage}
            ></button>
            {/* <a onClick={() => setShowAllocatedBudgetInfo(false)}>Cancel</a> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        centered
        show={deleteShow}
        onHide={() => setdeleteShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-uppercase text-center h6 my-0 text-cyan">
              Delete Confirmation
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this file?</Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setdeleteShow(false)}
            className="gradient-btn py-2"
            data="No"
          ></button>
          <button
            className="gradient-btn py-2"
            data="Yes"
            onClick={() =>
              deleteFile(selectedDBFile.id, selectedDBFile.s3FileName)
            }
          ></button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              {/* <div className="row justify-content-center mb-3">
                <div className="col-lg-12 text-center">
                  <p className="text-gray text-center promote-footer">
                    Time Cap *: 6 months for all files uploaded
                  </p>
                  <p className="text-gray text-center promote-footer">
                    Money Cap *: US$3000 for all files uploaded @ $600 each of 5 files.
                  </p>
                </div>
              </div> */}
              <div className="row justify-content-center mb-3">
                <div className="col-lg-12 text-center">
                  <h4 className="mb-0 text-white">
                    Early release discounted price for Advertiser
                  </h4>
                  <div>
                    <div className="uploadFile position-relative">
                      <div className="bg-danger text-white d-inline-flex p-1 rounded-1 balance">
                        Total: ${totalBudget}
                      </div>
                      <label for="fileUpload">
                        <CloudArrowUpFill />
                        <span>Upload videos (webp, mp4, webm)</span>
                        <small>
                          Upto {_limits.contentUploadVideos} videos (upto{" "}
                          {_limits.videoSize}MB each)
                        </small>
                      </label>
                      <input
                        type="file"
                        id="fileUpload"
                        onChange={handleFileChange}
                        className="d-none"
                      />
                      {/* <div className="url-input">
                        <textarea placeholder="Add Embedded URL" onChange={handleEmbeddedLinkChange} value={""} rows={3}> </textarea>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="currentColor"
                          class="bi bi-link-45deg"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"></path>
                          <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"></path>
                        </svg>
                      </div> */}
                      {selectedFile && (
                        <ul className="img-preview">
                          <li>
                            <span>
                              {/* {selectedFile.type.startsWith("image") ? (
                                <img src={selectedFileURL} alt="" />
                              ) : (
                                <video src={selectedFileURL} controls />
                              )} */}
                              {selectedFile.type.startsWith("audio") ? (
                                <audio src={selectedFileURL} controls />
                              ) : (
                                <video src={selectedFileURL} controls />
                              )}
                            </span>
                            {selectedFile.name}
                            <Trash3
                              className="trash_icon"
                              onClick={() => clearSelectedFile()}
                            />
                          </li>
                        </ul>
                      )}
                      {/* show after file upload */}
                      <div className="align-items-center justify-content-center d-flex gap-4 my-4">
                        <label className="text-nowrap d-block">Allocated Budget ($):
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#0fbbc7"
                            viewBox="0 0 16 16"
                            className="ml-5"
                            onClick={() => setShowAllocatedBudgetInfo(true)}
                          >
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                          </svg>
                        </label>
                        <input
                          className="form-control w-25"
                          placeholder="100"
                          value={allocatedBudget}
                          onChange={handleAllocatedBudget}
                        ></input>
                      </div>
                      <p class="mb-0 mt-3 opacity-75 small text-danger text-start mt-3">
                        Disclaimer: The uploader takes full responsibility for
                        the content and copyrights of any uploaded videos.
                      </p>
                      <button
                        className="gradient-btn mt-3"
                        data="Save"
                        onClick={() => saveFile()}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-8 mx-auto">
                  <div className="card card-cyan-bg h-100">
                    <div className="card-header py-3">
                      <h6 className="mb-0">Uploaded Files</h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-dark">
                        <thead>
                          <tr>
                            <th>Filename</th>
                            {/* <th>File Type</th> */}
                            <th>Size</th>
                            <th>Status</th>
                            <th>Allocated Budget</th>
                            <th>Remaining Balance</th>
                            <th>Expired At</th>
                            <th className="text-center">Active</th>
                            <th align="end" className="text-end">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {advertisements.map((advertisement) => (
                            <tr>
                              <td>{advertisement.customerFileName}</td>
                              {/* <td>{advertisement.fileType}</td> */}
                              <td>
                                {(
                                  advertisement.s3FileSize /
                                  (1024 * 1024)
                                ).toFixed(2)}
                                MB
                              </td>
                              <td>{advertisement.isApproved ? "Approved" : "In Review"}</td>
                              <td>${advertisement.allocatedBudget}</td>
                              <td>${advertisement.remainingBudget}</td>
                              <td>{advertisement.expiredAt ? format(advertisement.expiredAt, "dd MMMM, y") : ''}</td>
                              <td>
                                <label class="switch mx-auto d-flex">
                                  <input type="checkbox" onChange={(e) => handleIsAdvertise(e, advertisement)} checked={advertisement.isAdvertised ? true : false} />
                                  <span class="slider round"></span>
                                </label>
                              </td>
                              <td align="end">
                                <div className="d-flex gap-2 justify-content-end text-cyan">
                                  <ArrowClockwise
                                    className="cursor-pointer"
                                    onClick={() =>
                                      updateExpiredTime(advertisement.s3FileName, advertisement.id)
                                    }
                                  />
                                  <EyeFill
                                    className="cursor-pointer"
                                    onClick={() =>
                                      getFile(advertisement.s3FileName)
                                    }
                                  />
                                  <Trash3
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleDeleteFile(advertisement)
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                          {advertisements.length === 0 ? (
                            <tr>
                              <td className="text-center" colSpan={7}>
                                {" "}
                                No Record Found{" "}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-auto">
                <div className="col-lg-12">
                  {/* <p className="promote-footer">OPIC is currently using an advertisement cap set at $600 per video per month. For example, if you upload 5 videos you will be billed a maximum of $3000 per month.</p> */}
                  <p className="promote-footer">
                    Footer reference: Updated as of 29-November-2023.
                  </p>
                  <p className="promote-footer">
                    <b>Time Terms:</b> This is pertinent to the publicly loaded
                    media files. These media files shall remain accessible by
                    the advertisement engine for a period of 6 months. After
                    this time, the advertiser needs to establish a new contract
                    by logging into this form and saving it back again.
                  </p>
                  <p className="promote-footer">
                    <b>Allocated Budget:</b> Once your balance reaches this
                    level for the current invoice, your advertisement will stop
                    playing for this specific media file. Your used budget will
                    reflect on your invoice.
                  </p>
                  <p className="promote-footer">
                    <b>Disclaimer:</b> OPIC Technologies, Inc. is a small
                    private company with patented technology and believes it
                    will ultimately have the customer base to support video
                    advertisements to customers over time; however, we do not
                    have sufficient customer views to make this a guarantee.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default PromoteFiles;
