import React, { useEffect, useState } from "react";
import axios from "../../common/axiosInstance";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Messenger, Meta, Twitter, Whatsapp } from "react-bootstrap-icons";
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  _userTypes,
  _claimTypes,
  _milesClaim,
  _security,
} from "../../common/constants";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { checkTokenExpiry } from "../../common/utils";

function ReferralFiles() {
  useEffect(() => {
    checkTokenExpiry();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [referralURL, setReferralURL] = useState("");
  const [referrals, setReferrals] = useState([]);
  const [filterReferrals, setFilterReferrals] = useState([]);
  const [referralCreditsRedeemed, setReferralCreditsRedeemed] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userInfo.userType === _userTypes.user) {
      setReferralURL(
        window.location.origin + "/signup?referral=" + userInfo.username
      );
    }
    if (userInfo.userType === _userTypes.advertiser) {
      setReferralURL(
        window.location.origin +
          "/signup-advertiser?referral=" +
          userInfo.username
      );
    }

    getReferredUsers();
  }, []);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = referralURL;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    notifySuccess("Referral link is copied to clipboard successfully");
  };

  const gotoReferredUser = () => {
    window.location.href = `/referred-users`;
  };

  const getReferredUsers = async () => {
    setLoading(true);

    try {
      const response = await axios
        .get(`/referral?username=${userInfo.username}`)
        .then((response) => {
          const res = response.data;
          setReferrals(res.referrals);
          setFilterReferrals(res.referrals.slice(0, 5));
          setReferralCreditsRedeemed(res.referralCreditsRedeemed);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-xl-8">
                  <div className="d-flex h-100 justify-content-center p-4 referred-users">
                    <div className="row">
                      <div className="col-xl-8 col-lg-10 m-auto">
                        <h3 class="text-uppercase text-center h5 pt-1 pb-3 text-cyan">
                          Refer a friend and get {_milesClaim.referral} OPIC
                          spatial miles
                        </h3>
                        {referralCreditsRedeemed > 0 ? (
                          <h6 class="text-uppercase text-center h6 pb-3 text-success">
                            {referralCreditsRedeemed} Referral Miles Earned
                          </h6>
                        ) : (
                          ""
                        )}

                        <p className="pb-2 text-gray text-center small">
                          Refer a friend and get 200 OPIC spatial miles for
                          every 10 users. OPIC spatial miles cannot be exchanged
                          or transferred to other users. OPIC spatial miles
                          cannot be exchanged for cash.
                        </p>
                        <div className="refer-email">
                          <input
                            type="text"
                            className="form-control rounded-end-0"
                            readOnly
                            value={referralURL}
                          />
                          <button
                            className="gradient-btn btn-sm m-width-100"
                            data="Copy"
                            onClick={copyToClipboard}
                          ></button>
                        </div>
                        <ul className="claim-listing gap-5 justify-content-center pt-0 text-center flex-wrap">
                          <li>
                            <span className="claim-icon social m-auto mb-2">
                              <FacebookShareButton url={referralURL}>
                                <Meta className="fb" />
                              </FacebookShareButton>
                            </span>
                            <span>Facebook</span>
                          </li>
                          <li>
                            <span className="claim-icon social m-auto mb-2">
                              <TwitterShareButton url={referralURL}>
                                <Twitter className="twitter" />
                              </TwitterShareButton>
                            </span>{" "}
                            <span>Twitter</span>
                          </li>
                          <li>
                            <span className="claim-icon social m-auto mb-2">
                              <WhatsappShareButton url={referralURL}>
                                <Whatsapp className="whatsapp" />
                              </WhatsappShareButton>
                            </span>{" "}
                            <span>WhatsApp</span>
                          </li>
                          <li>
                            <span className="claim-icon social m-auto mb-2">
                              <FacebookMessengerShareButton url={referralURL}>
                                <Messenger className="messenger" />
                              </FacebookMessengerShareButton>
                            </span>{" "}
                            <span>Messenger</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 mt-4 mt-lg-0">
                  <div className="referred-users">
                    <h3 class="text-uppercase text-center h5 pt-1 pb-3 text-cyan">
                      Referred Users
                    </h3>
                    <ul className="referred-users-listing">
                      {filterReferrals.map((referral) => (
                        <li>
                          {/* <span>
                            <img
                              src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                              alt=""
                            ></img>
                          </span> */}
                          {referral.name}
                        </li>
                      ))}
                    </ul>
                    {referrals.length == 0 ? (
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="74"
                          height="74"
                          fill="#0a7677"
                          class="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                        </svg>
                        <h6 className="text-cyan opacity-50">No referred users</h6>
                      </div>
                    ) : (
                      ""
                    )}
                    {referrals.length > 5 ? (
                      <div className="text-center">
                        <button
                          className="gradient-btn btn-sm m-width-100"
                          data="View More"
                          onClick={() => gotoReferredUser()}
                        ></button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default ReferralFiles;
