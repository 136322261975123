import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from '../../common/axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import giftImage from "../../assets/img/gift.png";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { ArrowRightShort } from "react-bootstrap-icons";
import { _userTypes, _stripeProducts, _paymentStatus, _security } from "../../common/constants";
import { checkTokenExpiry } from "../../common/utils";

function PackagesUpgrade() {
  useEffect(() => {
    checkTokenExpiry();
    getStripeProducts();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const minQuantity = userInfo.userType === _userTypes.user ? 300 : 800;
  const [loading, setLoading] = useState(false);
  const [stripeProducts, setStripeProducts] = useState([]);
  const [quantity, setQuantity] = useState(minQuantity);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const instanceId = searchParams.get('instanceId');
  const peerId = searchParams.get('peerId');
  const quantityStyle = { "min-height": "inherit", padding: "0.5rem 1rem" };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const checkValidation = () => {
    if (quantity < minQuantity) {
      notifyError("The minimum quantity to be purchased is " + minQuantity);
      return false;
    }
    return true;
  };

  const getStripeProducts = async () => {
    setLoading(true);

    try {
      const response = await axios.get('/products')
        .then((response) => {
          let products = response.data.products;
          if (userInfo.userType === _userTypes.user) {
            products = products.filter(
              (x) => x.active === true && x.id === _stripeProducts.user
            );
          } else if (userInfo.userType === _userTypes.advertiser) {
            products = products.filter(
              (x) => x.active === true && x.id === _stripeProducts.advertiser
            );
          }
          setStripeProducts(products);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const createCheckoutSession = async (subscription) => {
    if (!checkValidation()) return;

    setLoading(true);

    try {
      const requestModal = {
        priceId: subscription.default_price,
        product: subscription.name,
        currency: subscription.price.currency,
        username: userInfo.username,
        quantity: quantity,
        isLoggedIn: true,
        callback: window.location.href
      };
      if (instanceId && peerId) {
        requestModal.instanceId = instanceId;
        requestModal.peerId = peerId;
      }
      const response = await axios.post('/checkoutsession', requestModal)
        .then((response) => {
          const res = response.data;
          window.location.href = res.data.url;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };
  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row justify-content-center mb-5">
                <div className="col-lg-8 text-center">
                  <h1 className="mb-2 text-uppercase text-center h5 pt-1 pb-3 text-cyan">
                    Purchase OPIC Miles
                  </h1>
                </div>
              </div>
              <div className="row justify-content-center g-3 g-xl-5">
                {
                  userInfo.userType === _userTypes.user ?
                    (
                      <div className="col-md-6 col-lg-6">
                        <div className={`card card-cyan-bg rounded-4 ${userInfo.paymentStatus === _paymentStatus.freeUser ? "active-plan" : ""}`}>
                          {
                            userInfo.paymentStatus === _paymentStatus.freeUser ?
                              (
                                <div className="active-badge">
                                  <p className="plan-text">Active Plan</p>
                                </div>
                              ) : ""
                          }
                          <div className="card-body p-4 d-flex flex-column justify-content-between">
                            <div>
                              <h4 className="align-items-center d-flex gap-3 h6 fw-bold mb-4">
                                <img height={62} width={62} src={giftImage} />
                                Freemium
                              </h4>
                              <ul className="list-unstyled mb-0 text-gray small services">
                                <li className="text-cyan fw-700">
                                  Explore and Engage in OPIC*Verse
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>
                                        Craft in Two Dimensions or Dive into Three
                                      </b>
                                    </summary>
                                    Engage a select audience of five with live 2D and
                                    3D content in your own private showcase room.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Broadcast Your World </b>
                                    </summary>
                                    Take the stage in a public room, sharing your
                                    2D/3D creations live with up to five viewers.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Daily Discovery Miles </b>
                                    </summary>
                                    Embark on a daily journey with +10 complimentary
                                    OPIC spatial miles, unlocking +10 minutes of
                                    vibrant 2D or 3D live streaming adventures. Users
                                    need to login daily to claim the +10 free miles
                                    and explore OPIC*Verse these free miles.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Miles that Keep on Giving </b>
                                    </summary>
                                    Your unused exploration miles roll over - keep
                                    collecting for continuous creation.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      
                                      <b>
                                        Refer and Expand Your Universe in OPIC*Verse
                                      </b>
                                    </summary>
                                    Bring friends into this spatial OPIC*Verse
                                    experience and earn 200 miles for every 10
                                    adventurers you introduce.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Ready for More? </b>
                                    </summary>
                                    The gateway to premium features is always open -
                                    upgrade at your pace premium anytime.
                                  </details>
                                </li>
                              </ul>
                              {/* <ul className="list-unstyled mb-4 small">
                                <li className="py-1">
                                  Each mile provides 1 minute of 3D livestream for up to 5 viewers.
                                </li>
                                <li className="py-1">
                                  Claim 10 free OPIC spatial miles every day to experience 10 minutes of free 3D live streaming.
                                </li>
                                <li className="py-1">
                                  Claim 200 free OPIC spatial miles for ever 10 users you refer.</li>
                                <li className="py-1">Upgrade to premium any time.</li>
                              </ul> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : ""
                }

                {stripeProducts.map((product) => (
                  <div className="col-md-6 col-lg-6">
                    <div className={`card card-cyan-bg rounded-4 ${userInfo.paymentStatus === _paymentStatus.premiumUser ? "active-plan" : ""}`}>
                      {
                        userInfo.paymentStatus === _paymentStatus.premiumUser ?
                          (
                            <div className="active-badge">
                              <p className="plan-text">Active Plan</p>
                            </div>
                          ) : ""
                      }
                      <div className="card-body p-4 d-flex flex-column justify-content-between">
                        <div>
                          <h4 className="align-items-center d-flex gap-3 h6 fw-bold mb-4">
                            <img
                              height={62}
                              width={62}
                              src={product?.images[0]}
                              className="text-center"
                            />
                            {product?.name} - $
                            {(product.price?.unit_amount / 100).toFixed(2)} /
                            mile
                          </h4>
                          {
                            userInfo.userType === _userTypes.user ? (
                              <ul className="list-unstyled mb-0 text-gray small services">
                                <li className="text-cyan">
                                  Elevate Your Experience in OPIC*Verse
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>All Freemium Features Included </b>
                                    </summary>
                                    Begin with all the exploratory freedoms of
                                    Freemium access.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Personalized 3D Worlds </b>
                                    </summary>
                                    Craft immersive 3D content for an intimate
                                    audience of five in your private room.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Public Showcase Expansion </b>
                                    </summary>
                                    Take center stage in a grander public room,
                                    captivating 5, 15, or even 25 live viewers with
                                    your 3D storytelling. More to come as we expand.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Pro-Grade Streaming </b>
                                    </summary>
                                    Access pro stereo 3D cameras for a broadcast that
                                    leaps through screens (see FAQ on Discord).
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Future Feature Alert #1 </b>
                                    </summary>
                                    Stay tuned for our upcoming recording option -
                                    your live creations, now replayable.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Premium Perk – Advertiser Alliance </b>
                                    </summary>
                                    Amplify your reach by partnering with an
                                    advertiser of your choice. Endorse and showcase
                                    their brand in your live 3D space, engaging your
                                    audience with daily promotional play.
                                  </details>
                                </li>
                              </ul>
                            ) : (
                              <ul className="list-unstyled mb-0 text-gray small services">
                                <li className="text-cyan fw-700">
                                  Elevate Your Brand with Premium Advertiser in
                                  OPIC*Verse
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>All-Access Pass </b>
                                    </summary>
                                    Immerse yourself in the full array of perks enjoyed
                                    by our Freemium community.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Sustained Spotlight </b>
                                    </summary>
                                    Secure your brand’s vibrant footprint in the
                                    OPIC*Verse Purchase OPIC spatial miles at a rate of
                                    $0.10 per minute. Minimum purchase is 800 miles as
                                    an esteemed Advertisement Starter Premium member.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Public Showcase Expansion </b>
                                    </summary>
                                    Command the virtual stage in expansive public rooms,
                                    captivating an audience of 5 to 25 live viewers with
                                    your immersive 3D narratives. Anticipate more
                                    opportunities as our universe, OPIC*Verse grows.
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Pro-Grade Streaming </b>
                                    </summary>
                                    Harness the power of professional stereo 3D cameras
                                    for a broadcast experience that transcends the
                                    ordinary, offering a glimpse into the future of
                                    digital engagement (details available in our FAQ on
                                    Discord).
                                  </details>
                                </li>
                                <li>
                                  <details>
                                    <summary>
                                      <b>Round-the-Clock Global Brand Amplification </b>
                                    </summary>
                                    Your brand in perpetual motion, showcased 24/7 to a
                                    worldwide audience. Simply provide your proprietary
                                    content and watch it orbit continuously. Engage with
                                    our audience as they navigate the OPIC*Verse,
                                    fostering connections that resonate with purpose and
                                    focus attention on your offerings at the moment of
                                    highest intent. You will be billed at $0.02 each
                                    time your advertisement video is played inside of a
                                    room.
                                  </details>
                                </li>
                              </ul>
                            )
                          }
                          {/* <ul className="list-unstyled mb-4 small">
                            <li className="py-1">Enjoy All Freemium Perks</li>
                            <li className="py-1">
                              Purchase OPIC spatial miles at a rate of $0.02 per minute.
                            </li>
                          </ul> */}
                          {/* <ul className="list-unstyled mb-5 text-gray">
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                              Enjoy All Freemium Perks
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                              Produce immersive 3D content for upto 5 live
                              audience
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                              Gain 200 spatial miles for every 10 users you
                              refer
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                              Exclusive Local recording - Coming soon
                            </li>
                            <li className="d-flex mb-2">
                              <ArrowRightShort className="flex-shrink-0 h4 mb-0" />
                              Stream from professional stereo 3D cameras -
                              Coming soon
                            </li>
                          </ul> */}
                          <div className="row mb-4 mt-3">
                            <div className="col-md-7">
                              <label className="w-100 mb-1 small">Quantity:</label>
                              <input
                                type="number"
                                placeholder="Select Quantity"
                                className="rounded-2 w-100 quantity form-control"
                                value={quantity}
                                onChange={handleQuantityChange}
                                style={quantityStyle}
                              />
                            </div>
                            <div className="col-md-5 text-end">
                              <label className="w-100 mb-1 small">Total:</label>
                              <p className="fw-bold h3 mb-0 text-cyan">
                                $
                                {(
                                  (product.price?.unit_amount / 100) *
                                  quantity
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-grid">
                          <button
                            type="button"
                            class="gradient-btn"
                            data=" Buy Now"
                            onClick={() => createCheckoutSession(product)}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}
export default PackagesUpgrade;
