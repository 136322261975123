import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeyFill, Eye, EyeSlash } from "react-bootstrap-icons";
import { checkTokenExpiry } from "../../common/utils";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import PasswordStrengthBar from "react-password-strength-bar";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { _security } from "../../common/constants";

function UpdatePassword() {

  useEffect(() => {
    checkTokenExpiry();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const passwordStrengthBarColor = [
    "rgba(0, 255, 255, 0.3607843137)",
    "#ef4836",
    "#f6b44d",
    "#2b90ef",
    "#25c281",
  ];

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const toggleCurrentShowPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const toggleRepeatShowPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handlePasswordStrengthStateChange = (score, feedback) => {
    if (score < 2) {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();

    if (password.trim() !== repeatPassword.trim()) {
      notifyError("Password doesn't matched");
      return;
    }
    setLoading(true);

    const requestModal = {
      currentPassword: currentPassword,
      password: password,
      repeatPassword: repeatPassword,
      username: userInfo.username
    };

    try {
      const response = await axios.post('/updatepassword', requestModal)
        .then((response) => {
          const res = response.data;
          setLoading(false);
          notifySuccess(res.message);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card mb-5">
              <h3 className="text-uppercase text-center h5 pt-1 pb-3 text-cyan">
                Change Password
              </h3>
              <form onSubmit={updatePassword}>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type={showCurrentPassword ? 'text' : 'password'}
                    placeholder="Current Password"
                    required=""
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                  />
                  <KeyFill className="icon" />
                  {
                    showCurrentPassword ? (
                      <EyeSlash onClick={toggleCurrentShowPassword} className="eye-slash" />
                    ) : (
                      <Eye onClick={toggleCurrentShowPassword} className="eye-icon" />
                    )
                  }
                </div>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    required=""
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <KeyFill className="icon" />
                  {
                    showPassword ? (
                      <EyeSlash onClick={toggleShowPassword} className="eye-slash" />
                    ) : (
                      <Eye onClick={toggleShowPassword} className="eye-icon" />
                    )
                  }
                  <PasswordStrengthBar
                    password={password}
                    barColors={passwordStrengthBarColor}
                    onChangeScore={handlePasswordStrengthStateChange}
                  />
                </div>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type={showRepeatPassword ? 'text' : 'password'}
                    placeholder="Repeat Password"
                    required=""
                    value={repeatPassword}
                    onChange={handleRepeatPasswordChange}
                  />
                  <KeyFill className="icon" />
                  {
                    showRepeatPassword ? (
                      <EyeSlash onClick={toggleRepeatShowPassword} className="eye-slash" />
                    ) : (
                      <Eye onClick={toggleRepeatShowPassword} className="eye-icon" />
                    )
                  }
                  <PasswordStrengthBar
                    password={repeatPassword}
                    barColors={passwordStrengthBarColor}
                    onChangeScore={handlePasswordStrengthStateChange}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className={`gradient-btn w-100" ${formDisabled ? "disabled" : ""}`}
                    disabled={formDisabled}
                    data="Update"
                  ></button>
                </div>
              </form>
            </div>
          </section>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default UpdatePassword;
