import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { _userTypes, _claimTypes, _milesClaim, _security } from "../../common/constants";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { checkTokenExpiry } from "../../common/utils";

function ReferredUsers() {

  useEffect(() => {
    checkTokenExpiry();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem('user'));
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getReferredUsers();
  }, []);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const getReferredUsers = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`/referral?username=${userInfo.username}`)
        .then((response) => {
          const res = response.data;
          setReferrals(res.referrals);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 referred-users-page">
                  <div className="referred-users">
                    <h3 class="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                      Referred Users
                    </h3>
                    <ul className="referred-users-listing">
                      {referrals.map((referral) => (
                        <li>
                          {/* <span>
                            <img
                              src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                              alt=""
                            ></img>
                          </span> */}
                          {referral.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default ReferredUsers;
