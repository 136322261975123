import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
// import ReCAPTCHA from "react-google-recaptcha";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import forgetIcon from "../../assets/img/password-reset.png";
import { KeyFill, Person, RocketTakeoff, Eye, EyeSlash } from "react-bootstrap-icons";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { _limits, _paymentStatus, _userTypes, _security } from "../../common/constants";

function Login() {
  const navigate = useNavigate();

  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 800);

    const getQueryParamValue = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get('callback');
      return paramValue;
    };

    // Get the query parameter value
    const callback = getQueryParamValue();

    if (callback) {
      // If the query parameter exists, save it in localStorage
      localStorage.setItem('callback', callback);
    }

    const authToken = localStorage.getItem('token');
    const authTokenExpiry = localStorage.getItem('authTokenExpiry');

    if (authToken && authTokenExpiry) {
      const expirationDate = new Date(authTokenExpiry);
      if (expirationDate > new Date()) {
        navigate('/lobby');
      }
    } else {
      // Token has expired or doesn't exist; require user to log in
    }

  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  // const [recaptchaValue, setRecaptchaValue] = useState(null);
  // const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleRecaptchaChange = (value) => {
  //   setRecaptchaValue(value);
  //   setRecaptchaValid(true);
  // };

  // const handleRecaptchaExpired = (value) => {
  //   setRecaptchaValid(false);
  // };

  // const handleRecaptchaError = (value) => {
  //   setRecaptchaValid(false);
  // };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    if (value >= 90) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const launch = async (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      notifyError('Please Swipe to Login');
      return;
    }

    const requestModal = {
      username,
      password,
      // recaptchaValue,
      rememberMe
    };
    try {
      setLoading(true);
      const response = await axios.post('/launch', requestModal)
        .then((response) => {
          const res = response.data;
          localStorage.setItem("token", res.token);
          localStorage.setItem("user", JSON.stringify(res.user));
          // navigate("/otp");
          const user = res.user;
          if (user.rememberMe) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + _limits.rememberMe);
            localStorage.setItem('authTokenExpiry', currentDate.toISOString());
          }
          if (user.paymentStatus === _paymentStatus.newUser) {
            navigate("/choose-package");
          } else {
            if (user.userType === _userTypes.admin) {
              navigate("/admin-promote");
            } else {
              navigate("/lobby");
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card m-auto">
              <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                Launch
              </h3>
              <form onSubmit={launch}>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Username or Email"
                    required=""
                    value={username}
                    onChange={handleUsernameChange}
                  />
                  <Person className="icon" />
                </div>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    required=""
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <KeyFill className="icon" />
                  {
                    showPassword ? (
                      <EyeSlash onClick={toggleShowPassword} className="eye-slash" />
                    ) : (
                      <Eye onClick={toggleShowPassword} className="eye-icon" />
                    )
                  }
                </div>
                {/* <div className="d-flex justify-content-center position-relative mb-3">
                  <ReCAPTCHA
                    sitekey={_security.googleCaptchaSiteKey}
                    onChange={handleRecaptchaChange}
                    onErrored={handleRecaptchaError}
                    onExpired={handleRecaptchaExpired}
                    theme={"dark"}
                  />
                </div> */}
                <div className="d-flex justify-content-center position-relative mb-3">
                  Swipe to Login!
                </div>
                <div className="d-flex justify-content-center position-relative mb-3">
                  <Slider
                    value={sliderValue}
                    onChange={handleSliderChange}
                    withBars
                    min={0}
                    max={100}
                  />
                </div>
                <div className="pb-4 d-flex justify-content-between align-items-center small text-gray mt-4">
                  <Link
                    to="/forget-pass"
                    className="nav-link d-flex align-items-center gap-2"
                  >
                    <img width={20} src={forgetIcon} alt="forget" />
                    Forgot Password?
                  </Link>
                  <span className="d-flex align-items-center gap-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      Remember Me?
                    </label>
                  </span>
                </div>
                <div className="d-flex flex-wrap gap-3 justify-content-center mb-3 mt-5 position-relative">
                  <div className="icon-rocket border position-absolute z-2 bg-black p-2 rounded-circle">
                    {/* <img src={rocketIcon} /> */}
                    <RocketTakeoff className="fs-5 text-cyan" />
                  </div>
                  {/*
                    <button
                      className={`gradient-btn w-100 " ${!recaptchaValid ? "disabled" : ""
                        }`}
                      data="Launch Now"
                      disabled={!recaptchaValid}
                    ></button>
                  */}
                  <button
                    className={`gradient-btn w-100 " ${!captchaVerified ? "disabled" : ""}`}
                    data="Launch Now"
                  ></button>
                </div>
                <div className="d-flex justify-content-center text-gray small">
                  Don't have an account?
                  <Link to="/signup" className="nav-link text-cyan">
                    <span className="">&nbsp;Signup</span>
                  </Link>
                </div>
              </form>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}

export default Login;
