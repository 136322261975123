import React, { useEffect, useState } from "react";
import axios from "../../common/axiosInstance";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import giftImage from "../../assets/img/gift.png";
import crownImage from "../../assets/img/premium.png";
import adImage from "../../assets/img/ad.png";
import animationData from "../../components/lottie/animation.json";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Lottie from "lottie-react";
import PasswordStrengthBar from "react-password-strength-bar";
import { _security, _userTypes } from "../../common/constants";
import rocket from "../../assets/icons/icon-rocket-launch.png";

const countryCodes = require("country-codes-list");
const myCountryCodesObject = countryCodes.customList(
  "countryCallingCode",
  "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
);

const options = Object.entries(myCountryCodesObject).map(([code, label]) => ({
  value: code + "-" + label.split("] ")[0].substring(1),
  label: label,
}));

options.unshift({
  value: "1-US",
  label: "[US] United States of America: +1",
});

options.push({
  value: "1-CA",
  label: "[CA] Canada: +1",
});

const geoRegions = [
  {
    value: "NALA",
    label: "North & Latin America",
  },
  {
    value: "EMEA",
    label: "Europe & Middle East",
  },
  {
    value: "APAC",
    label: "Asia Pacific",
  },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#b4b4b4",
    boxShadow: "none",
    fontSize: "0.87rem",
    padding: "0 1.125rem",
    border: "1px solid #00ffff5c !important",
    height: "52px",
    borderRadius: "7px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.87rem",
    background: state.isSelected ? "gray" : "#002424",
    color: state.isSelected ? "black" : "white",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#b4b4b4",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#b4b4b4",
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    color: "#b4b4b4",
  }),
};

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const signup = async (userData) => {
  try {
    const response = await axios
      .post("/signup", userData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

function Signup() {
  useEffect(() => {
    const getQueryParamValue = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const referral = urlParams.get("referral");
      return referral;
    };

    // Get the query parameter value
    const referral = getQueryParamValue();

    if (referral) {
      setReferral(referral);
    } else {
      let callback = localStorage.getItem("callback");
      if (callback) {
        setReferral(callback);
      }
    }
  }, []);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountry] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [region, setRegion] = useState("");
  const [aboveAge18, setAboveAge18] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [referral, setReferral] = useState("");
  const [isAdvertiserSignedUp, setIsAdvertiserSignedUp] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      username,
      email,
      password,
      countryCode,
      phoneCode,
      phoneNumber,
      aboveAge18,
      userType: isAdvertiserSignedUp ? _userTypes.advertiser : _userTypes.user,
      referral,
      region,
    };

    if (formData.aboveAge18) {
      formData.aboveAge18 = "1";
    } else {
      formData.aboveAge18 = "0";
    }

    if (isAdvertiserSignedUp) {
      formData.companyName = companyName;
      formData.businessType = businessType;
    }

    try {
      setLoading(true);
      setFormDisabled(true);
      const response = await signup(formData);
      navigate("/confirm-mail");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 400) {
        notifyError(error.response.data.message);
      }
      setFormDisabled(false);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCountryChange = (e) => {
    setPhoneCode("+" + e.value.split("-")[0]);
    setCountry(e.value.split("-")[1]);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleBusinessType = (e) => {
    setBusinessType(e.target.value);
  };

  const handleAboveAge18Change = (e) => {
    setAboveAge18(e.target.checked);
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handlePasswordStrengthStateChange = (score, feedback) => {
    if (score < 2) {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  };

  const handleRegionChange = (e) => {
    setRegion(e.value);
  };

  const handleSignupBtnChange = (status) => {
    setIsAdvertiserSignedUp(status);
    if (status) {
      geoRegions.unshift({
        value: "all",
        label: "All Regions",
      });
    } else {
      let index = geoRegions.findIndex((x) => x.value === "all");
      geoRegions.splice(index, 1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const passwordStrengthBarColors = [
    "rgba(0, 255, 255, 0.3607843137)",
    "#ef4836",
    "#f6b44d",
    "#2b90ef",
    "#25c281",
  ];

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper">
            <div className="row">
              <div className="col-xl-7 mb-5 mb-lg-0 order-1 order-xl-0">
                <h5 className="mb-4 mt-4 mt-xl-0 text-center text-cyan">
                  Now it's easier to make a choice
                </h5>
                {isAdvertiserSignedUp ? (
                  <div className="my-3 row align-items-center">
                    <div className="col-md-9 order-1 order-md-0 text-gray">
                      <ul className="list-unstyled mb-0 text-gray small services">
                        <li className="text-cyan fw-700">
                          Elevate Your Brand with Premium Advertiser in
                          OPIC*Verse
                        </li>
                        {/* <li>
                          <details>
                            <summary>
                              <b>All-Access Pass </b>
                            </summary>
                            Immerse yourself in the full array of perks enjoyed
                            by our Freemium community.
                          </details>
                        </li> */}
                        <li>
                          <details>
                            <summary>
                              <b>Sustained Spotlight </b>
                            </summary>{" "}
                            Secure your brand’s vibrant footprint in the
                            OPIC*Verse Purchase OPIC spatial miles at a rate of
                            $0.20 per minute. Minimum purchase is 800 miles as
                            an esteemed Advertisement Starter Premium member.
                          </details>
                        </li>
                        <li>
                          <details>
                            <summary>
                              <b>Public Showcase Expansion </b>
                            </summary>{" "}
                            Command the virtual stage in expansive public rooms,
                            captivating an audience of up to 25 live viewers with
                            your immersive 3D narratives. Anticipate more
                            opportunities as our universe, OPIC*Verse grows.
                          </details>
                        </li>
                        <li>
                          <details>
                            <summary>
                              <b>Pro-Grade Streaming </b>
                            </summary>{" "}
                            Harness the power of professional stereo 3D cameras
                            for a broadcast experience that transcends the
                            ordinary, offering a glimpse into the future of
                            digital engagement (details available in our FAQ on
                            Discord).
                          </details>
                        </li>
                        <li>
                          <details>
                            <summary>
                              <b>Round-the-Clock Global Brand Amplification </b>
                            </summary>
                            Your brand in perpetual motion, showcased 24/7 to a
                            worldwide audience. Simply provide your proprietary
                            content and watch it orbit continuously. Engage with
                            our audience as they navigate the OPIC*Verse,
                            fostering connections that resonate with purpose and
                            focus attention on your offerings at the moment of
                            highest intent. You will be billed at $0.03 each time your advertisement video is played to a user.
                          </details>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-3 text-center mb-3 mb-md-0 text-center">
                      <img src={adImage} alt="Gift" />
                      <p className="my-0 text-cyan fst-italic">Advertiser</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="my-3 row align-items-center">
                      <div className="col-md-9 order-1 order-md-0 text-gray">
                        <ul className="list-unstyled mb-0 text-gray small services">
                          <li className="text-cyan fw-700">
                            Explore and Engage in OPIC*Verse
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>
                                  Craft in Two Dimensions or Dive into Three{" "}
                                </b>
                              </summary>
                              Engage a select audience of five with live 2D and
                              3D content in your own private showcase room.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Broadcast Your World </b>
                              </summary>{" "}
                              Take the stage in a public room, sharing your
                              2D/3D creations live with up to five viewers.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Daily Discovery Miles </b>
                              </summary>{" "}
                              Embark on a daily journey with +10 complimentary
                              OPIC spatial miles, unlocking +10 minutes of
                              vibrant 2D or 3D live streaming adventures. Users
                              need to login daily to claim the +10 free miles
                              and explore OPIC*Verse these free miles.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Miles that Keep on Giving </b>
                              </summary>{" "}
                              Your unused exploration miles roll over - keep
                              collecting for continuous creation.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                {" "}
                                <b>
                                  Refer and Expand Your Universe in OPIC*Verse{" "}
                                </b>
                              </summary>
                              Bring friends into this spatial OPIC*Verse
                              experience and earn 200 miles for every 10
                              adventurers you introduce.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Ready for More? </b>
                              </summary>
                              The gateway to premium features is always open -
                              upgrade at your pace premium anytime.
                            </details>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3 text-center mb-3 mb-md-0 text-center">
                        <img src={giftImage} alt="Gift" />
                        <p className="my-0 text-cyan fst-italic">Freemium</p>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-3 text-center mb-3 mb-lg-0">
                        <img src={crownImage} alt="Crown" />
                        <p className="my-0 text-cyan fst-italic">Premium</p>
                      </div>
                      <div className="col-md-9">
                        <ul className="list-unstyled mb-0 text-gray small services">
                          <li className="text-cyan">
                            Elevate Your Experience in OPIC*Verse
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>All Freemium Features Included </b>
                              </summary>
                              Begin with all the exploratory freedoms of
                              Freemium access.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Personalized 3D Worlds </b>
                              </summary>
                              Craft immersive 3D content for an intimate
                              audience of five in your private room.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Public Showcase Expansion </b>
                              </summary>
                              Take center stage in a grander public room,
                              captivating 5, 15, or even 25 live viewers with
                              your 3D storytelling. More to come as we expand.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Pro-Grade Streaming </b>
                              </summary>
                              Access pro stereo 3D cameras for a broadcast that
                              leaps through screens (see FAQ on Discord).
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Future Feature Alert #1 </b>
                              </summary>
                              Stay tuned for our upcoming recording option -
                              your live creations, now replayable.
                            </details>
                          </li>
                          <li>
                            <details>
                              <summary>
                                <b>Premium Perk – Advertiser Alliance </b>
                              </summary>
                              Amplify your reach by partnering with an
                              advertiser of your choice. Endorse and showcase
                              their brand in your live 3D space, engaging your
                              audience with daily promotional play.
                            </details>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
                <p className="text-danger small mt-3 lh-base">
                  Note: OPIC Spatial miles cannot be transferred or
                  exchanged for cash at this time. The amount of time that 1 mile provides
                  varies based on the size of the audience. For example, rates
                  as of 12 Dec 2023 are as follows:
                  <br></br>
                  <ul>
                    <li>
                      Tier A: 1 mile/minute for an audience of up to 5 users
                    </li>
                    <li>
                      Tier B: 2 mile/minute for an audience of up to 15 users
                    </li>
                    <li>
                      Tier C: 3 mile/minute for an audience of up to 25 users
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-xl-5 mb-5 mb-lg-0 mt-4 mt-xl-0 signup">
                <div className="auth-card">
                  <h3 className="text-uppercase text-center h4 pt-1 text-cyan">
                    Sign up to OPIC
                  </h3>
                  <div className="d-flex justify-content-center gap-4 my-4">
                    <button
                      className={`opic-btn text-white " ${!isAdvertiserSignedUp ? "selected" : ""
                        }`}
                      onClick={() => handleSignupBtnChange(false)}
                    >
                      <img height={30} src={rocket} />
                      Join as Producer/
                      <br />
                      Consumer
                    </button>
                    <button
                      className={`opic-btn text-white " ${isAdvertiserSignedUp ? "selected" : ""
                        }`}
                      onClick={() => handleSignupBtnChange(true)}
                    >
                      <img height={30} src={adImage} />
                      Join as Advertiser
                    </button>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Your name"
                          value={name}
                          onChange={handleNameChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Username"
                          value={username}
                          onChange={handleUsernameChange}
                          required
                        />
                      </div>
                    </div>
                    {isAdvertiserSignedUp ? (
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Company Name"
                            value={companyName}
                            onChange={handleCompanyName}
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Business Type"
                            value={businessType}
                            onChange={handleBusinessType}
                            required
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="col-12 mb-3">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email address"
                          value={email}
                          onChange={handleEmailChange}
                          required
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <div className="input-icon">
                          <input
                            className="form-control"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                          />
                          {
                            showPassword ? (
                              <EyeSlash onClick={toggleShowPassword} className="eye-slash" />
                            ) : (
                              <Eye onClick={toggleShowPassword} className="eye-icon" />
                            )
                          }
                          <PasswordStrengthBar
                            password={password}
                            barColors={passwordStrengthBarColors}
                            onChangeScore={handlePasswordStrengthStateChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-3">
                        <Select
                          options={geoRegions}
                          isSearchable={true}
                          styles={customStyles}
                          placeholder="Geographic Region"
                          onChange={handleRegionChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3 theme-select">
                        <Select
                          options={options}
                          isSearchable={true}
                          styles={customStyles}
                          onChange={handleCountryChange}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone"
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="age"
                        checked={aboveAge18}
                        onChange={handleAboveAge18Change}
                      />
                      <label
                        className="form-check-label text-gray"
                        htmlFor="age"
                      >
                        Are you above 18 years of age?
                      </label>
                    </div>
                    <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="terms"
                        checked={termsChecked}
                        onChange={handleTermsChange}
                      />
                      <label
                        className="form-check-label text-gray"
                        htmlFor="terms"
                      >
                        I agree to
                        <Link
                          target="_blank"
                          to="https://opic3d.com/terms-and-conditions/"
                          className="text-decoration-none text-cyan"
                        >
                          &nbsp;Terms &amp; Conditions
                        </Link>
                      </label>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className={`gradient-btn w-100 ${formDisabled || !termsChecked ? "disabled" : ""
                          }`}
                        disabled={formDisabled || !termsChecked}
                        data="Sign Up"
                      ></button>
                    </div>
                    <div className="d-flex mt-3 justify-content-center text-gray small">
                      Already have an account?
                      <Link to="/launch" className="nav-link text-cyan">
                        &nbsp;Launch
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}

export default Signup;
