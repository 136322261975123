import jwtDecode from 'jwt-decode';

export function checkTokenExpiry() {

    const token = localStorage.getItem('token');

    if (token) {
        // Decode the token
        const decodedToken = jwtDecode(token);

        // Check if the token has an expiration time
        if (decodedToken.exp) {
            // Get the current timestamp
            const currentTime = Date.now() / 1000;

            // Compare the token's expiration time with the current time
            if (decodedToken.exp < currentTime) {
                // Token has expired
                // return true;
                localStorage.clear();
                window.location.href = window.location.origin;

            } else {
                // Token is still valid
                // return false;
            }
        }
    } else {
        localStorage.clear();
        window.location.href = window.location.origin;
    }
}