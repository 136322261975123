import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { EyeFill, Check2Square, Trash3, XSquare } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { _limits, _security } from "../../common/constants";
import { checkTokenExpiry } from "../../common/utils";

function AdminPromoteFiles() {
  useEffect(() => {
    checkTokenExpiry();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const [selectedDBFile, setSelectedDBFile] = useState(null);
  const [deleteShow, setdeleteShow] = useState(false);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const getAdvertisements = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/alladv')
        .then((response) => {
          let advertisements = response.data.advertisements;
          setAdvertisements(advertisements);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getFile = async (s3FileKey) => {
    try {
      setLoading(true);
      const requestModal = {
        username: userInfo.username,
        s3FileKey: s3FileKey,
      };
      const response = await axios.post('/file', requestModal)
        .then((response) => {
          let url = response.data.signedUrl;
          setLoading(false);
          window.open(url, "_blank");
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const deleteFile = async (s3bucketId, s3FileKey) => {

    try {
      const requestModal = {
        username: userInfo.username,
        s3bucketId: s3bucketId,
        s3FileKey: s3FileKey
      };
      setdeleteShow(false);
      setLoading(true);
      const response = await axios.post('/deletefile', requestModal)
        .then((response) => {
          getAdvertisements();
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const approveAdv = async (advertisementId) => {

    try {
      setLoading(true);
      const requestModal = {
        username: userInfo.username,
        advertisementId: advertisementId
      };
      const response = await axios.post('/approveadv', requestModal)
        .then((response) => {
          setLoading(false);
          notifySuccess(response.message);
          let adv = advertisements.find(x=> x.id === advertisementId);
          adv.isApproved = true;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const rejectAdv = async (advertisementId) => {

    try {
      const requestModal = {
        username: userInfo.username,
        advertisementId: advertisementId
      };
      setLoading(true);
      const response = await axios.post('/rejectadv', requestModal)
        .then((response) => {
          setLoading(false);
          notifySuccess(response.message);
          let adv = advertisements.find(x=> x.id === advertisementId);
          adv.isApproved = false;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const handleDeleteFile = (file) => {
    setSelectedDBFile(file);
    setdeleteShow(true);
  }

  useEffect(() => {
    getAdvertisements();
  }, []);

  return (
    <>
      <Modal
        size="sm"
        centered
        show={deleteShow}
        onHide={() => setdeleteShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-uppercase text-center h6 my-0 text-cyan">
              Delete Confirmation
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this file?</Modal.Body>
        <Modal.Footer>
          <button onClick={() => setdeleteShow(false)} className="gradient-btn py-2" data="No"></button>
          <button
            className="gradient-btn py-2"
            data="Yes"
            onClick={() =>
              deleteFile(selectedDBFile.id, selectedDBFile.s3FileName)
            }
          ></button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="card card-cyan-bg h-100">
                    <div className="card-header py-3">
                      <h6 className="mb-0">All Advertisements</h6>
                    </div>
                    <div className="card-body">
                      <table className="table table-dark">
                        <thead>
                          <tr>
                            <th>Username</th>
                            <th>Filename</th>
                            <th>File Type</th>
                            <th>Size</th>
                            <th>Status</th>
                            <th align="end" className="text-end">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {advertisements.map((advertisement) => (
                            <tr>
                              <td>{advertisement.username}</td>
                              <td>{advertisement.s3FileName}</td>
                              <td>{advertisement.fileType}</td>
                              <td>
                                {(
                                  advertisement.s3FileSize /
                                  (1024 * 1024)
                                ).toFixed(2)}
                                MB
                              </td>
                              <td>
                                {advertisement.isApproved ? 'Approved' : 'Not Approved'}
                              </td>
                              <td align="end">
                                <div className="d-flex gap-2 justify-content-end text-cyan">
                                  <EyeFill
                                    className="cursor-pointer"
                                    onClick={() =>
                                      getFile(advertisement.s3FileName)
                                    }
                                  />
                                  <Trash3
                                    className="cursor-pointer"
                                    onClick={() => handleDeleteFile(advertisement)}
                                  />
                                  {advertisement.isApproved ? (
                                    <XSquare
                                      className="cursor-pointer"
                                      onClick={() => rejectAdv(advertisement.id)}
                                    />
                                  ) :
                                    <Check2Square
                                      className="cursor-pointer"
                                      onClick={() => approveAdv(advertisement.id)}
                                    />
                                  }
                                </div>
                              </td>
                            </tr>
                          ))}
                          {advertisements.length === 0 ? (
                            <tr>
                              <td className="text-center" colSpan={6}>
                                No Record Found
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer/>
        </DashboardLayout>
      )}
    </>
  );
}

export default AdminPromoteFiles;
