import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../assets/icons/opic-logo.webp";

function AuthLayout({ children }) {
  const location = useLocation();
  return (
    <>
      <nav className="navbar navbar-absolute navbar-expand-lg navbar-transparent py-2 py-lg-4">
        <div className="container justify-content-center">
          <div className="navbar-wrapper">
            <div className="navbar-toggle d-inline">
              <button type="button" className="navbar-toggler">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <a className="navbar-brand me-0" href="#">
              <Link to='/' className="text-decoration-none">
                <img
                  src={logo}
                  alt=""
                />
              <p> 
                3D LiVe <span>Conversation</span> FOR <span>EVERYONE</span>
              </p>
              </Link>
            </a>
          </div>
        </div>
      </nav>
      <div className="container">
        <div className="auth-box">
          <main>{children}</main>
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
