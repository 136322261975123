const _enviroment = "development";

const _security = {
    apiKey: "HPsooe3Zoi1UGq9VWfBonah2SDsBxkxV3kClDvs3",
    apiBaseUrl: "https://ouhpczzj9i.execute-api.us-east-1.amazonaws.com",
    apiSuffix: "prod",
    baseURL: "https://live.opic3d.com",
    googleCaptchaSiteKey: '6LeezmgjAAAAAHEncIGgf9kIsBbC2yY9QiqzVg84'
};

const _userTypes = {
    user: 1,
    advertiser: 2,
    admin: 3
};

const _userStatus = {
    active: 1,
    inActive: 2,
    deleted: 3
};

const _paymentStatus = {
    newUser: 1,
    freeUser: 2,
    premiumUser: 3
};

const _limits = {
    otpExpiryMinutes: 2,
    contentUploadVideos: 5,
    contentUploadAudios: 3,
    contentUploadImages: 3,
    fileSize: 50, // IN MB
    imageSize: 5, // IN MB
    // videoSize: 50, // IN MB
    videoSize: 500, // IN MB
    audioSize: 5, // IN MB
    rememberMe: 2, // IN DAYS
    totalRefferals: 10,
    s3BucketLinkExpiry: 8, // IN HOURS
    s3BucketPromotionLinkExpiry: 90, // IN DAYS
    miles: 10,
    allowedConsumer: 5,
    playAdvMins: 3,
    advDefaultAllocatedBudget: 100
};

const _claimTypes = {
    firstTimeLogin: 1,
    dailyLogin: 2,
    referral: 3
};

const _milesClaim = {
    firstTimeLogin: 50,
    // dailyLogin: 10,
    dailyLogin: 200,
    referral: 200
};

const _stripeProducts = {
    // DEV PRODUCTS
    // user       : 'prod_OolGAbpZjGQY3m',
    // advertiser : 'prod_OpYea1sl6rWPPs'
    // PRODUCTION PRODUCTS
    user: 'prod_P66jwncPXvap3l',
    advertiser: 'prod_P66iHvV5FtXfzm'
};

const _minTimeToStartRoom = 3; // it will send me a notification after this time

module.exports = { _enviroment, _security, _userTypes, _userStatus, _paymentStatus, _limits, _claimTypes, _milesClaim, _stripeProducts, _minTimeToStartRoom }