import React, { useEffect, useState } from "react";
import axios from "../../common/axiosInstance";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { _userTypes, _claimTypes, _milesClaim, _security } from "../../common/constants";
import { checkTokenExpiry } from "../../common/utils";

function BlockedUsers() {

  useEffect(() => {
    checkTokenExpiry();
  }, []);

  useEffect(() => {
    getBlockedUsers();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem('user'));
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const getBlockedUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/blockuser?username=${userInfo.username}`)
        .then((response) => {
          const res = response.data;
          setBlockedUsers(res.users);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const unblockUser = async (blockId) => {
    setLoading(true);
    try {
      const response = await axios.delete(`/blockuser?id=${blockId}`)
        .then((response) => {
          getBlockedUsers();
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-9 mx-auto referred-users-page">
                  <div className="referred-users">
                    <h3 class="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                      Blocked Users
                    </h3>
                    {
                        blockedUsers.length === 0 ? (
                          <p className="text-center">
                            No blocked user found!
                          </p>
                        ): ''
                      }
                    <ul className="referred-users-listing">
                      {blockedUsers.map((user) => (
                        <li className="position-relative">
                          {/* <span>
                            <img
                              src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44"
                              alt=""
                            ></img>
                          </span> */}
                          {user.username}
                          <button className="remove-blocked" onClick={()=> unblockUser(user.id)}>+</button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default BlockedUsers;
