import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Person } from "react-bootstrap-icons";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { _security } from "../../common/constants";
import { format } from "date-fns";
import { checkTokenExpiry } from "../../common/utils";

function AddReservedUser() {

  useEffect(() => {
    checkTokenExpiry();
    getReservedUsers();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [username, setUsername] = useState('');
  const [reservedUsers, setReservedUsers] = useState([]);
  const [formDisabled, setFormDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    if (username.length === 0) {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  }

  const getReservedUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/addreserveduser')
        .then((response) => {
          setReservedUsers(response.data.reservedUsers);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const addReservedUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requestModal = {
      username: username,
      createdBy: userInfo.username
    };

    try {
      const response = await axios.post('/addreserveduser', requestModal)
        .then((response) => {
          const res = response.data;
          notifySuccess(res.message);
          setUsername('');
          getReservedUsers();
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <section className="page-wrapper mt-4">
            <div className="row">
              <div className="auth-card mb-5">
                <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                  Add Reserved User
                </h3>
                <form onSubmit={addReservedUser}>
                  <div className="input-icon mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Reserverd Username"
                      required=""
                      value={username}
                      onChange={handleUsernameChange}
                    />
                    <Person className="icon" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className={`gradient-btn w-100" ${formDisabled ? "disabled" : ""}`}
                      disabled={formDisabled}
                      data="Add"
                    ></button>
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="card card-cyan-bg h-100">
                  <div className="card-header py-3">
                    <h6 className="mb-0">Reserved Users</h6>
                  </div>
                  <div className="card-body">
                    <table className="table table-dark">
                      <thead>
                        <tr>
                          <th>Reserverd Username</th>
                          <th>Created By</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reservedUsers.map((user) => (
                          <tr>
                            <td>{user?.username}</td>
                            <td>{user?.createdBy}</td>
                            <td>{user.createdAt ? format(user.createdAt, "dd MMMM, y") : ''}</td>
                          </tr>
                        ))}
                        {reservedUsers.length === 0 ? (
                          <tr>
                            <td className="text-center" colSpan={3}>
                              No Record Found
                            </td>
                          </tr>
                        ) : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default AddReservedUser;
