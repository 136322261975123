import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../common/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import roboIcon from "../../assets/img/robo.png";
import dailyIcon from "../../assets/img/daily.png";
import produceIcon from "../../assets/icons/icon-produce-2.png";
import consumeIcon from "../../assets/icons/icon-consume-1.png";
import promoteIcon from "../../assets/icons/icon-AD-1.png";
import agreementIcon from "../../assets/icons/handshake.png";
import vibeIcon from "../../assets/img/confetti.png";
import kandooCam from "../../assets/img/kandoocam.webp";
import cannonCam from "../../assets/img/cannon.png";
import cannonFishCam from "../../assets/img/cannonFishCam.png";
import vrShineCon from "../../assets/img/vr-shineconn.png";
import loadingGif from "../../assets/img/Rolling-1s-200px.gif";
import Modal from "react-bootstrap/Modal";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { checkTokenExpiry } from "../../common/utils";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { format } from "date-fns";
import { Calendar2Check, Check2All } from "react-bootstrap-icons";
import {
  _claimTypes,
  _milesClaim,
  _userTypes,
  _security,
  _minTimeToStartRoom,
} from "../../common/constants";
import logo from "../../assets/img/opic-icon.png";
import discordLogo from "../../assets/img/discord.png";
import ReactSignatureCanvas from "react-signature-canvas";

function Home() {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);

    checkTokenExpiry();
    checkUserClaim();
    getUserMiles();
    getUserCharges();
    getUser();

    let callbackURL = localStorage.getItem("callback");
    if (callbackURL) {
      setRoomId(callbackURL);
      setRoomIDJoinShow(true);
      localStorage.removeItem("callback");
    }
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [spinning, setSpinning] = useState(null);
  const [claimStatus, setClaimStatus] = useState({});
  const [claimMessage, setClaimMessage] = useState('');
  const [totalMiles, setTotalMiles] = useState(0);
  const [remMiles, setRemMiles] = useState(0);
  const [usedMiles, setUsedMiles] = useState(0);
  const [claimedMiles, setClaimedMiles] = useState(0);
  const [charges, setCharges] = useState([]);
  const [show, setShow] = useState(false);
  const [congratsShow, setCongratsShow] = useState(false);
  const [roomIDJoin, setRoomIDJoinShow] = useState(false);
  const [advertisementAgreementShow, setAdvertisementAgreementShow] = useState(false);
  const [isAdvertisementAgreement, setIsAdvertisementAgreement] = useState(false);
  const [isAdvSpinning, setIsAdvSpinning] = useState(false);
  const [roomIDAI, setRoomIDAIShow] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [publicRooms, setPublicRooms] = useState([]);
  const [isPublicRoomLoading, setIsPublicRoomLoading] = useState(false);
  const [isPublicRoom, setIsPublicRoom] = useState(false);
  const [publicRoomStatus, setPublicRoomStatus] = useState(0);
  const [formDisabled, setFormDisabled] = useState(true);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const dailyLoginAlert = ({ closeToast }) => (
    <div className="d-flex justify-content-between claim-listing">
      <div className="d-flex align-items-center gap-3">
        <div className="claim-icon">
          <Calendar2Check />
        </div>
        <div>
          <h6 className="mb-0">Daily Login</h6>
          <span className="text-cyan">Log in Today</span>
        </div>
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className="d-flex flex-column text-end">
          <span className="text-cyan r">+{_milesClaim.dailyLogin}</span>
          <span>Miles</span>
        </div>
        <button
          onClick={() => {
            closeToast();
            handleShow();
          }}
          className="gradient-btn btn-sm ms-2 m-width-100"
          data="Redeem Now"
        ></button>
      </div>
    </div>
  );

  const firstLoginAlert = ({ closeToast }) => (
    <div className="d-flex justify-content-between small">
      <div className="d-flex align-items-center gap-2">
        <img height={32} src={dailyIcon} />
        <span>First Time Login</span>
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex flex-column text-end">
          <span className="text-cyan r">+{_milesClaim.firstTimeLogin}</span>
          <span>Miles</span>
        </div>
        <button
          onClick={() => {
            closeToast();
            handleShow();
          }}
          className="gradient-btn btn-sm ms-2 m-width-100"
          data="Redeem Now"
        ></button>
      </div>
    </div>
  );

  const referralAlert = ({ closeToast }) => (
    <div className="d-flex justify-content-between small">
      <div className="d-flex align-items-center gap-2">
        <img height={32} src={dailyIcon} />
        <span>User Referral</span>
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex flex-column text-end">
          <span className="text-cyan r">+{_milesClaim.referral}</span>
          <span>Miles</span>
        </div>
        <button
          onClick={() => {
            closeToast();
            handleShow();
          }}
          className="gradient-btn btn-sm ms-2 m-width-100"
          data="Redeem Now"
        ></button>
      </div>
    </div>
  );

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/user?username=${userInfo.username}`)
        .then((response) => {
          let user = response.data.user;
          if (user) {
            setIsAdvertisementAgreement(user.isAdvertisementAgreement);
          }
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getUserCharges = async () => {
    setLoading(true);
    try {
      const response = await axios
        .get(`/charge?username=${userInfo.username}`)
        .then((response) => {
          let charges = response.data.charges;
          setCharges(charges);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getPubicRooms = async () => {
    try {
      setIsPublicRoomLoading(true);
      const response = await axios.get("/publicroom").then((response) => {
        setIsPublicRoomLoading(false);
        let rooms = response.data.rooms;
        setPublicRooms(rooms);
        if (rooms.length === 0) {
          setPublicRoomStatus(1);
        } else {
          setPublicRoomStatus(2);
        }
      });
    } catch (error) {
      setIsPublicRoomLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const getUserMiles = async () => {
    setLoading(true);
    try {
      const response = await axios
        .get(`/miles?username=${userInfo.username}`)
        .then((response) => {
          const data = response.data;
          setTotalMiles(data.totalPurchasedMiles + data.totalEarnedMiles);
          setRemMiles(data.remainingMiles);
          setUsedMiles(data.usedMiles);
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const checkUserClaim = async () => {
    setLoading(true);
    try {
      const response = await axios
        .get(`/checkclaim?username=${userInfo.username}`)
        .then((response) => {
          const claim = response.data;
          setClaimStatus(claim);

          if (!claim.firstTimeLogin) {
            toast(firstLoginAlert, {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          } else if (!claim.dailyLogin) {
            // toast(dailyLoginAlert, {
            //   position: "top-center",
            //   autoClose: false,
            //   hideProgressBar: false,
            //   closeOnClick: false,
            //   pauseOnHover: true,
            //   draggable: false,
            //   progress: undefined,
            //   theme: "dark",
            // });
            claimMiles(_claimTypes.dailyLogin);
          } else if (!claim.referral) {
            toast(referralAlert, {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "dark",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const claimMiles = async (claimType) => {
    setSpinning(claimType);
    try {
      const requestModal = {
        username: userInfo.username,
        claimType: claimType,
      };
      const response = await axios
        .post("/claim", requestModal)
        .then((response) => {
          const data = response.data.claims;
          setTotalMiles(data.totalPurchasedMiles + data.totalEarnedMiles);
          setRemMiles(data.remainingMiles);
          if (claimType === _claimTypes.firstTimeLogin) {
            setClaimedMiles(_milesClaim.firstTimeLogin);
            setClaimMessage(`You just claimed +${_milesClaim.firstTimeLogin} miles`)
          } else if (claimType === _claimTypes.dailyLogin) {
            setClaimedMiles(_milesClaim.dailyLogin);
            setClaimMessage(`Congratulations! We've added an extra ${_milesClaim.dailyLogin} miles to your account for logging in daily.`)
          } else if (claimType === _claimTypes.referral) {
            setClaimedMiles(_milesClaim.referral);
            setClaimMessage(`You just claimed +${_milesClaim.referral} miles`)
          }

          handleClose();
          setCongratsShow(true);
          checkUserClaim();

          setSpinning(null);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setSpinning(null);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const produceStream = async () => {
    setLoading(true);
    try {
      const response = await axios
        .get(`/userinfo?username=${userInfo.username}`)
        .then((response) => {
          let user = response.data.user;
          if (user) {
            if (user.allowedMinutes <= _minTimeToStartRoom) {
              setLoading(false);
              notifyError(
                `At least ${_minTimeToStartRoom} mins credit required to start the room.`
              );
              return;
            } else {
              window.location.href = `${_security.baseURL
                }/?token=${localStorage.getItem("token")}`;
              setLoading(true);
            }
          }
          {
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const acceptAdvAgreement = async (e) => {
    e.preventDefault();
    setIsAdvSpinning(true);
    try {
      const response = await axios
        .post('/acceptadvagreement', { username: userInfo.username })
        .then((response) => {
          setIsAdvSpinning(true);
          navigate('/promote');
        })
    } catch (error) {
      setIsAdvSpinning(true);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const promoteAds = () => {
    if (isAdvertisementAgreement) {
      navigate('/promote');
    } else {
      setAdvertisementAgreementShow(true);
    }

  };

  const joinStream = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setRoomIDJoinShow(false);
      const response = await axios.get(`/room?roomId=${roomId}&username=${userInfo.username}`)
        .then((response) => {
          window.location.href = `${_security.baseURL
            }/?token=${localStorage.getItem("token")}&roomId=${roomId}`;
        });
    } catch (error) {
      setLoading(false);
      setRoomIDJoinShow(true);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const handleRoomIdChange = (e) => {
    setRoomId(e.target.value);
  };

  const referNow = () => {
    navigate("/referral");
  };

  const openAIModal = () => {
    setPublicRoomStatus(0);
    setRoomIDAIShow(true);
    setPublicRooms([]);
  };

  const openJoinRoomModal = () => {
    setIsPublicRoom(false);
    setRoomIDJoinShow(true);
  };

  const joinPublicRoom = (publicRoomId) => {
    // setLoading(true);
    setRoomIDAIShow(false);
    setRoomId(publicRoomId);
    setIsPublicRoom(true);
    setRoomIDJoinShow(true);
    // window.location.href = `${_security.baseURL}/?token=${localStorage.getItem("token")}&roomId=${publicRoomId}`;
  };

  const handleSignatureEndEvent = () => {
    setFormDisabled(false);
  };

  const openAdvertisement = (url) => {
    window.open(url, "_blank");
  };

  const openPdfInNewTab = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <ToastContainer className="header-toast" />
      {/* Welcome Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to OPIC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="claim-listing">
            <div className="col-auto">
              <div className="d-flex align-items-center gap-3">
                <div className="claim-icon">
                  <Calendar2Check />
                </div>
                <div>
                  <h6 className="mb-0">First Time Login</h6>
                  <span className="text-cyan fst-italic">
                    Exclusively yours
                  </span>
                </div>
              </div>
            </div>
            <div className="align-items-center col-auto d-flex">
              <span className="text-cyan">+{_milesClaim.firstTimeLogin}</span>
              &nbsp;Miles
              {spinning === _claimTypes.firstTimeLogin ? (
                <span className="align-items-center d-inline-flex justify-content-center m-width-100 ms-2">
                  <img height={24} src={loadingGif} />
                </span>
              ) : (
                <>
                  {claimStatus.firstTimeLogin ? (
                    <>
                      <span className="m-width-100 ms-2 text-center text-cyan">
                        <Check2All className="fs-6 me-1 text-cyan" />
                        Claimed
                      </span>
                    </>
                  ) : (
                    <button
                      className={
                        "gradient-btn btn-sm ms-2 m-width-100 " +
                        (claimStatus.firstTimeLogin ? "disabled" : "")
                      }
                      data={claimStatus.firstTimeLogin ? "Claimed" : "Claim"}
                      onClick={() => claimMiles(_claimTypes.firstTimeLogin)}
                      disabled={claimStatus.firstTimeLogin ? true : false}
                    ></button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="claim-listing">
            <div className="col-auto">
              <div className="d-flex align-items-center gap-3">
                <div className="claim-icon">
                  <Calendar2Check />
                </div>
                <div>
                  <h6 className="mb-0">Daily Login</h6>
                  <span className="text-cyan fst-italic">Log in Today</span>
                </div>
              </div>
            </div>
            <div className="align-items-center col-auto d-flex">
              <span className="text-cyan">+{_milesClaim.dailyLogin}</span>{" "}
              &nbsp;Miles
              {spinning === _claimTypes.dailyLogin ? (
                <span className="align-items-center d-inline-flex justify-content-center m-width-100 ms-2">
                  <img height={24} src={loadingGif} />
                </span>
              ) : (
                <>
                  {claimStatus.dailyLogin ? (
                    <>
                      <span className="m-width-100 ms-2 text-center text-cyan">
                        <Check2All className="fs-6 me-1 text-cyan" />
                        Claimed
                      </span>
                    </>
                  ) : (
                    <button
                      className={
                        "gradient-btn btn-sm ms-2 m-width-100 " +
                        (claimStatus.dailyLogin ? "disabled" : "")
                      }
                      data="Claim"
                      onClick={() => claimMiles(_claimTypes.dailyLogin)}
                      disabled={claimStatus.dailyLogin ? true : false}
                    ></button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="claim-listing">
            <div className="col-auto">
              <div className="d-flex align-items-center gap-3">
                <div className="claim-icon">
                  <Calendar2Check />
                </div>
                <div>
                  <h6 className="mb-0">10 User Referral</h6>
                  <span className="text-cyan fst-italic" onClick={referNow}>
                    Refer Now!
                  </span>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="align-items-center col-auto d-flex">
                <span className="text-cyan">+{_milesClaim.referral}</span>{" "}
                &nbsp;Miles
                {spinning === _claimTypes.referral ? (
                  <span className="align-items-center d-inline-flex justify-content-center m-width-100 ms-2">
                    <img height={24} src={loadingGif} />
                  </span>
                ) : (
                  <>
                    {claimStatus.referral ? (
                      <>
                        <span className="m-width-100 ms-2 text-center text-cyan">
                          <Check2All className="fs-6 me-1 text-cyan" />
                          Claimed
                        </span>
                      </>
                    ) : (
                      <button
                        className={
                          "gradient-btn btn-sm ms-2 m-width-100 " +
                          (claimStatus.referral ? "disabled" : "")
                        }
                        data="Claim"
                        onClick={() => claimMiles(_claimTypes.referral)}
                        disabled={claimStatus.referral ? true : false}
                      ></button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="text-decoration-none cursor-pointer"
            onClick={handleClose}
          >
            Cancel
          </a>
        </Modal.Footer>
      </Modal>
      {/* Congrats Modal */}
      <Modal
        size="sm"
        show={congratsShow}
        onHide={() => setCongratsShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Congrats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-3 text-center">
            <p className="mb-0 small">
            {/* You just claimed +{claimedMiles} miles */}
            {claimMessage}
            </p>
            <h2 className="mb-0 fw-bold">{totalMiles}</h2>
            <p className="mb-0 small">Current miles remaining</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="gradient-btn px-4 py-2"
            data="Continue"
            onClick={() => setCongratsShow(false)}
          ></button>
        </Modal.Footer>
      </Modal>
      {/*Advertisement Agreement Modal */}
      <Modal
        size="md"
        show={advertisementAgreementShow}
        onHide={() => setAdvertisementAgreementShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> <img className="h-40" src={agreementIcon} /> Engage in OPIC Advertisements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 h-150 bg-cyan rounded mb-3">
            <img className="h-100 object-fit-contain w-100" src={promoteIcon} />
          </div>
          <form onSubmit={acceptAdvAgreement}>
            <p className="light-cyan">Do you agree to pay $0.03 each time your company's advertisements are played to a user inside of an OPIC room?</p>
            <p className="small light-cyan my-3">
              <ul>
                <li>You will receive an invoice at a future date.</li>
              </ul>
            </p>
            <div className="d-flex justify-content-end align-items-center gap-3">
              <a onClick={() => setAdvertisementAgreementShow(false)}>Cancel</a>
              {
                isAdvSpinning ? (
                  <button className="gradient-btn px-3 py-2">
                    <span className="align-items-center d-inline-flex justify-content-center m-width-100">
                      <img height={24} src={loadingGif} />
                    </span>
                  </button>
                ) : (
                  <button className="gradient-btn px-3 py-2 m-width-100" data="Agree" type="submit"></button>
                )
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*Enter Room Id */}
      <Modal
        size="md"
        show={roomIDJoin}
        onHide={() => setRoomIDJoinShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Consume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 h-150 bg-cyan rounded mb-3">
            <img className="h-100 object-fit-contain w-100" src={consumeIcon} />
          </div>
          {isPublicRoom ? (
            <form onSubmit={joinStream}>
              <p className="h3 text-center text-white">{roomId}</p>
              <p className="small light-cyan my-3">
                <ul>
                  <li>You are about to enter a Public Room.</li>
                  <li>
                    Content produced in public rooms must be safe and
                    appropriate for all audiences in public rooms.
                  </li>
                  <li>
                    ⚠️OPIC does not store and does not routinely monitor any
                    content in public rooms. Each user in a public room is
                    responsible for his / her own content.
                  </li>
                </ul>
              </p>
              <div className="d-flex justify-content-end align-items-center gap-3">
                <a onClick={() => setRoomIDJoinShow(false)}>Cancel</a>
                <button
                  className="gradient-btn px-3 py-2"
                  data="Accept"
                  type="submit"
                ></button>
              </div>
            </form>
          ) : (
            <>
              <p className="small light-cyan my-3 ">
                <ul>
                  <li>You are about to enter a Private Room.</li>
                  <li>
                    Content produced in private rooms must be safe and
                    appropriate for all audiences in private rooms.
                  </li>
                  <li>
                    ⚠️OPIC does not view, does not monitor and does not store
                    any content in private rooms. Each user in a private room is
                    responsible for his / her own content.
                  </li>
                </ul>
              </p>
              <form onSubmit={joinStream}>
                {/* <h6>Enter Room ID to Join</h6> */}
                <input
                  className="form-control"
                  type="text"
                  placeholder="Room Id"
                  value={roomId}
                  onChange={handleRoomIdChange}
                  required
                />
                <div className="d-flex justify-content-center">
                  <button
                    className="gradient-btn mt-4 px-3 py-2"
                    data="Join Now"
                    type="submit"
                  ></button>
                </div>
              </form>
            </>
          )}
        </Modal.Body>
      </Modal>
      {/* AI POD Modal */}
      <Modal show={roomIDAI} onHide={() => setRoomIDAIShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <img height={40} src={roboIcon} /> e___AI * AI ___o
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="small">
            Write something with your finger or mouse on this trackpad and you
            shall see a couple of public rooms to embrace…
          </p>
          <ReactSignatureCanvas
            onEnd={() => handleSignatureEndEvent()}
            penColor="cyan"
            canvasProps={{ className: "sigCanvas" }}
          />
          <div className="d-flex justify-content-center">
            {publicRooms.length === 0 ? (
              <button
                className={`gradient-btn px-4 py-2 ${formDisabled ? "disabled" : ""
                  }`}
                disabled={formDisabled}
                onClick={getPubicRooms}
                data={!isPublicRoomLoading ? "Let AI do some work!" : ""}
              >
                {isPublicRoomLoading ? (
                  <span className="align-items-center d-inline-flex justify-content-center m-width-100 ms-2">
                    <img height={24} src={loadingGif} />
                  </span>
                ) : (
                  ""
                )}
              </button>
            ) : (
              ""
            )}
          </div>
          {publicRoomStatus === 1 ? (
            <>
              {/* <p className="light-cyan">Results:</p> */}
              <ul className="ai-results border list-unstyled mt-2">
                <li>Sorry.. All live rooms are private. Try again sooner</li>
              </ul>
            </>
          ) : (
            ""
          )}

          {publicRooms.length > 0 ? (
            <>
              {/* <p className="light-cyan">Results:</p> */}
              {publicRooms.map((room) => (
                <>
                  <ul className="ai-results border list-unstyled mt-2">
                    <li onClick={() => joinPublicRoom(room.roomId)}>
                      {room.roomId}
                    </li>
                  </ul>
                </>
              ))}
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <span className="light-cyan small m-0 mx-auto text-danger">AI POD</span>
        </Modal.Footer> */}
      </Modal>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <div className="page-wrapper">
            <div className="row">
              <div className="col-auto d-flex align-items-center">
                <h4 className="my-0 text-cyan">Lobby</h4>
              </div>
              <div className="col-auto col-xl-2 col-lg-3 ms-auto">
                <div className="border card h-100 card-red-bg hover-bg d-flex flex-row gap-3 justify-content-center p-2 ps-3 align-items-center small">
                  <span className="text-white">
                    Feeling <span className="text-cyan">the vibe,</span>
                    <br />
                    {userInfo.username}
                  </span>
                  <img height={50} src={vibeIcon} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-10 col-lg-9">
                <div className="row mt-4">
                  {userInfo.userType === _userTypes.advertiser ? (
                    <div className="col-md-4">
                      <div
                        className="card card-cyan-bg hover-bg d-flex flex-row p-3 gap-3 mb-4"
                        onClick={() => promoteAds()}
                      >
                        <div className="icons-dashboard">
                          <img src={promoteIcon} />
                        </div>
                        <span className="flex-grow-1 mb-0 small">
                          Upload advertisement videos to play for all
                        </span>
                        <svg
                          onClick={() =>
                            openPdfInNewTab(
                              // "https://opic3d.com/wp-content/uploads/2023/12/producer_guide.pdf"
                              "https://opic3d.com/wp-content/uploads/2023/12/Advertiser__via_upload_video_advertisement.pdf"
                            )
                          }
                          className="icon-info-card"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#0fbbc7"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                        </svg>
                        <div className="cyan-pattern"></div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={`col-md-${userInfo.userType === _userTypes.advertiser ? "4" : "6"
                      }`}
                  >
                    <div className="card card-cyan-bg hover-bg d-flex flex-row p-3 gap-3 mb-4">
                      <div
                        className="icons-dashboard"
                        onClick={() => produceStream()}
                      >
                        <img src={produceIcon} />
                      </div>
                      <span
                        className={`${userInfo.userType === _userTypes.advertiser
                          ? "small"
                          : ""
                          } flex-grow-1 mb-0`}
                        onClick={() => produceStream()}
                      >
                        {userInfo.userType === _userTypes.advertiser
                          ? "Produce a 2D or 3D livestream advertisement"
                          : "Produce"}
                      </span>
                      <svg
                        onClick={() =>
                          openPdfInNewTab(
                            "https://opic3d.com/wp-content/uploads/2023/12/producer_guide.pdf"
                          )
                        }
                        className="icon-info-card"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#0fbbc7"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                      </svg>
                      <div
                        className="cyan-pattern"
                        onClick={() => produceStream()}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`col-md-${userInfo.userType === _userTypes.advertiser ? "4" : "6"
                      }`}
                  >
                    <div className="card card-cyan-bg hover-bg d-flex flex-row p-3 gap-3 mb-4">
                      <div className="icons-dashboard">
                        <img src={consumeIcon} />
                      </div>
                      <span className="flex-grow-1">
                        <span
                          className={`${userInfo.userType === _userTypes.advertiser
                            ? "small"
                            : ""
                            } mb-0 d-block`}
                        >
                          Consume
                        </span>
                        <svg
                          onClick={() =>
                            openPdfInNewTab(
                              "https://opic3d.com/wp-content/uploads/2023/12/Consumer_combined.pdf"
                            )
                          }
                          className="icon-info-card"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#0fbbc7"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                        </svg>
                        <div className="d-flex small align-items-center flex-wrap">
                          <span
                            className="text-decoration-underline cyan"
                            onClick={() => openJoinRoomModal()}
                          >
                            Enter RoomID
                          </span>
                          <span className="mx-2">OR</span>
                          <span
                            onClick={() => openAIModal()}
                            className="text-decoration-underline cyan me-1"
                          >
                            Ask OPIC's AI pod
                          </span>
                          <img height={24} src={roboIcon} />
                        </div>
                      </span>
                      <div className="cyan-pattern"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 mb-4 mb-xl-0">
                    <h6 className="mb-3 text-center">
                      <img className="me-2" src={logo} height={16} />
                      Memories
                    </h6>
                  </div>
                  <div className="col-xl-8 mb-4 mb-xl-0">
                    <div className="card card-cyan-bg h-100">
                      <div className="card-header py-3">
                        <h6 className="mb-0">Purchases</h6>
                      </div>
                      <div className="card-body">
                        <table className="table table-dark">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Product</th>
                              <th>Quantity</th>
                              <th align="end" className="text-end">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {charges.map((charge) => (
                              <tr>
                                <td>
                                  {format(charge.created * 1000, "dd MMMM, y")}
                                </td>
                                <td>{charge.product}</td>
                                <td>{charge.quantity}x</td>
                                <td align="end">
                                  ${charge.amount_total / 100}
                                </td>
                              </tr>
                            ))}
                            {charges.length === 0 ? (
                              <tr>
                                <td className="text-center" colSpan={4}>
                                  It's quiet in here... Time to add
                                  some excitement?
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="card card-cyan-bg">
                      <div className="card-header py-3">
                        <h6 className="mb-0">Engagements</h6>
                      </div>
                      <div className="card-body">
                        <p className="small d-flex justify-content-between">
                          Sign up Date
                          <span>
                            {format(userInfo.createdAt, "dd MMMM, y")}
                          </span>
                        </p>
                        <div className="d-flex justify-content-between align-items-center small py-2">
                          <Progress
                            type="circle"
                            status="default"
                            theme={{
                              default: {
                                color: "#0fbbc7",
                                trailColor: "white",
                              },
                            }}
                            strokeWidth={10}
                            width={50}
                            percent={
                              (remMiles / totalMiles) * 100 === 0
                                ? 100
                                : (remMiles / totalMiles) * 100
                            }
                          />
                          <span className="text-end">
                            Total Miles
                            <br />
                            {totalMiles}
                          </span>
                          <span className="text-end">
                            Miles Remaining
                            <br />
                            {/* {(totalMiles - usedMiles).toFixed(2)} */}
                            {remMiles.toFixed(2)}
                          </span>
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center small py-3">
                            <Progress
                              type="circle"
                              status="default"
                              theme={{
                                default: {
                                  Symbol: "",
                                  color: "#f56e6e",
                                  trailColor: "white",
                                },
                              }}
                              strokeWidth={10}
                              width={50}
                              percent={40}
                            />
                            <span className="text-end">
                              Remaining Purchased Miles:
                              <br />
                              50
                            </span>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-xl-8">
                    <div className="card card-cyan-bg">
                      <div className="card-body small promote-footer p-2 d-flex align-items-center gap-2 flex-wrap">
                        <img className="me-2" height={22} src={discordLogo} />
                        For FAQ and support, please join <a className="text-cyan" href="https://discord.com/invite/DqQbSrS8" target="_blank">OPIC3D’s 3D LiVe
                          Conversations & Community</a> on Discord.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3">
                <div className="mt-4">
                  <div className="hover-bg">
                    <div>
                      <a href="https://www.digitalocean.com/?refcode=677dbcae6827&utm_campaign=Referral_Invite&utm_medium=Referral_Program&utm_source=badge">
                        <img
                          src="https://web-platforms.sfo2.cdn.digitaloceanspaces.com/WWW/Badge%201.svg"
                          target="_blank"
                          alt="DigitalOcean Referral Badge"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="ad-box">
                    <h5>Kandao QooCam EGO</h5>
                    <a
                      target="_blank"
                      href="https://www.amazon.com/KanDao-Digital-Capture-Stereoscopic-Stabilization/dp/B0C22Z6NK5/ref=sr_1_1_sspa?crid=3DZZHEBECY2UZ&amp;keywords=kandao+qoocam&amp;qid=1701672677&amp;sprefix=kandao%25252Caps%25252C1555&amp;sr=8-1-spons&amp;ufe=app_do%25253Aamzn1.fos.17d9e15d-4e43-4581-b373-0e5c1a776d5d&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;psc=1&amp;_encoding=UTF8&amp;tag=opic3d-20&amp;linkCode=ur2&amp;linkId=ed1f1cbf05d952743c42b697168ca36b&amp;camp=1789&amp;creative=9325&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=ce82c6c85207f8c518b4f646334f5fce&camp=1789&creative=9325"
                    >
                      <img src={kandooCam} alt="KanDao QooCam EGO" />
                    </a>
                    <button
                      className="gradient-btn"
                      onClick={() =>
                        openAdvertisement(
                          "https://www.amazon.com/KanDao-Digital-Capture-Stereoscopic-Stabilization/dp/B0C22Z6NK5/ref=sr_1_1_sspa?crid=3DZZHEBECY2UZ&amp;keywords=kandao+qoocam&amp;qid=1701672677&amp;sprefix=kandao%25252Caps%25252C1555&amp;sr=8-1-spons&amp;ufe=app_do%25253Aamzn1.fos.17d9e15d-4e43-4581-b373-0e5c1a776d5d&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;psc=1&amp;_encoding=UTF8&amp;tag=opic3d-20&amp;linkCode=ur2&amp;linkId=ed1f1cbf05d952743c42b697168ca36b&amp;camp=1789&amp;creative=9325&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=ce82c6c85207f8c518b4f646334f5fce&camp=1789&creative=9325"
                        )
                      }
                    >
                      Order Now
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="ad-box">
                    <h5>Canon EOS R5C</h5>
                    <a
                      target="_blank"
                      href="https://www.amazon.com/Canon-Oversampling-Lightweight-Dual-Pixel-Dual-Fisheye/dp/B0B1935YM9/ref=sr_1_2?crid=3O7CXUH25GU78&amp;keywords=canon%252B5rc&amp;qid=1701673912&amp;sprefix=canon%252B5rc%252Caps%252C418&amp;sr=8-2&amp;ufe=app_do%253Aamzn1.fos.17f26c18-b61b-4ce9-8a28-de351f41cffb&amp;th=1&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=b694df351ee0c1bd07b84776b6232aad&camp=1789&creative=9325"
                    >
                      <img src={cannonCam} alt="Canon EOS R5" />
                    </a>
                    <button
                      className="gradient-btn"
                      onClick={() =>
                        openAdvertisement(
                          "https://www.amazon.com/Canon-Oversampling-Lightweight-Dual-Pixel-Dual-Fisheye/dp/B0B1935YM9/ref=sr_1_2?crid=3O7CXUH25GU78&amp;keywords=canon%252B5rc&amp;qid=1701673912&amp;sprefix=canon%252B5rc%252Caps%252C418&amp;sr=8-2&amp;ufe=app_do%253Aamzn1.fos.17f26c18-b61b-4ce9-8a28-de351f41cffb&amp;th=1&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=b694df351ee0c1bd07b84776b6232aad&camp=1789&creative=9325"
                        )
                      }
                    >
                      Order Now
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="ad-box">
                    <h5>Fisheye lens for Canon</h5>
                    <a
                      target="_blank"
                      href="https://www.amazon.com/Canon-RF5-2mm-F2-8-Dual-Fisheye/dp/B09HVBDKFY/ref=sr_1_3?crid=Q1DSV4UNFB14&amp;keywords=dual+fisheye+lens+canon&amp;qid=1701674196&amp;sprefix=dual+fish+eye%252Caps%252C360&amp;sr=8-3&amp;ufe=app_do%253Aamzn1.fos.17f26c18-b61b-4ce9-8a28-de351f41cffb&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=188d98426b68a43d9e37d7d9cd0a35a9&camp=1789&creative=9325"
                    >
                      <img src={cannonFishCam} alt="Canon Dual Fish Eye Lens" />
                    </a>
                    <button
                      className="gradient-btn"
                      onClick={() =>
                        openAdvertisement(
                          "https://www.amazon.com/Canon-RF5-2mm-F2-8-Dual-Fisheye/dp/B09HVBDKFY/ref=sr_1_3?crid=Q1DSV4UNFB14&amp;keywords=dual+fisheye+lens+canon&amp;qid=1701674196&amp;sprefix=dual+fish+eye%252Caps%252C360&amp;sr=8-3&amp;ufe=app_do%253Aamzn1.fos.17f26c18-b61b-4ce9-8a28-de351f41cffb&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=188d98426b68a43d9e37d7d9cd0a35a9&camp=1789&creative=9325"
                        )
                      }
                    >
                      Order Now
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="ad-box">
                    <h5>VR ShineCON</h5>
                    <a
                      target="_blank"
                      href="https://www.amazon.com/dp/B0BJZC9XMM?psc=1&amp;ref=ppx_yo2ov_dt_b_product_details&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=bd5f540beb0280e6caab744f8fd64d47&camp=1789&creative=9325"
                    >
                      <img src={vrShineCon} alt="Canon Dual Fish Eye Lens" />
                    </a>
                    <button
                      className="gradient-btn"
                      onClick={() =>
                        openAdvertisement(
                          "https://www.amazon.com/dp/B0BJZC9XMM?psc=1&amp;ref=ppx_yo2ov_dt_b_product_details&_encoding=UTF8&tag=opic3d-20&linkCode=ur2&linkId=bd5f540beb0280e6caab744f8fd64d47&camp=1789&creative=9325"
                        )
                      }
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}
export default Home;
