import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import { Route, Routes } from "react-router";
import Login from "./pages/auth/login";
import { BrowserRouter } from "react-router-dom";
import Signup from "./pages/auth/signup";
import ForgetPass from "./pages/auth/forgetPass";
import ChangePass from "./pages/auth/changePass";
import Home from "./pages/dashboard/home";
import OTP from "./pages/auth/otp";
import ForgotOTP from "./pages/auth/forgotOTP";
import TAndC from "./pages/auth/terms&conditions";
import MailConfirm from "./pages/auth/mail-confirm";
import Packages from "./pages/auth/package-select";
import VerifyLink from "./pages/auth/verify-link";
import Setting from "./pages/dashboard/settings";
import PackagesUpgrade from "./pages/dashboard/package-upgrade";
import PaymentSuccess from "./pages/auth/payment-success";
import PaymentFailed from "./pages/auth/payment-failed";
import PromoteFiles from "./pages/dashboard/promote";
import ReferralFiles from "./pages/dashboard/referral";
import ReferredUsers from "./pages/dashboard/referred-user";
import UpdatePassword from "./pages/dashboard/update-password";
import AdminPromoteFiles from "./pages/dashboard/admin-promote";
import AddReservedUser from "./pages/dashboard/add-reserved-user";
import BlockedUsers from "./pages/dashboard/blocked-user";
// import Gallery from "./pages/dashboard/gallery";
import TagManager from 'react-gtm-module'
import MaintenancePage from "./pages/auth/maintenance";
const tagManagerArgs = {
  gtmId: 'G-TQ00BM77QY'
}
TagManager.initialize(tagManagerArgs)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} exact />
      {/* <Route path="/launch" element={<MaintenancePage />} />
      <Route path="/signup" element={<MaintenancePage />} /> */}
      <Route path="/launch" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forget-pass" element={<ForgetPass />} />
      <Route path="/change-pass" element={<ChangePass />} />
      <Route path="/confirm-mail" element={<MailConfirm />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/forgotOTP" element={<ForgotOTP />} />
      <Route path="/verify" element={<VerifyLink />} />

      <Route path="/choose-package" element={<Packages />} />
      <Route path="/upgrade-package" element={<PackagesUpgrade />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failed" element={<PaymentFailed />} />

      <Route path="/lobby" element={<Home />} />
      <Route path="/promote" element={<PromoteFiles />} />
      <Route path="/referral" element={<ReferralFiles />} />
      <Route path="/referred-users" element={<ReferredUsers />} />
      <Route path="/blocked-users" element={<BlockedUsers />} />
      <Route path="/settings" element={<Setting />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/termsAndCondtions" element={<TAndC />} />

      <Route path="/admin-promote" element={<AdminPromoteFiles />} />
      <Route path="/add-reserved-user" element={<AddReservedUser />} />

      {/* <Route path="/gallery" element={<Gallery/>} /> */}

    </Routes>
  </BrowserRouter>
);
