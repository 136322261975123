import React from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/layout/auth-layout";
import failed from "../../assets/img/failed.png";

function PaymentFailed() {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const callback = searchParams.get('callback');

  const navigateToPackages = () => {
    if (callback) {
      window.location.href = callback;
    } else {
      navigate('/choose-package');
    }
  };

  return (
    <>
      <AuthLayout>
        <section className="page-wrapper mt-4">
          <div className="container mt-auto">
            <div className="row align-items-center">
              <div className="col-xl-5 mb-5 mb-lg-0 mt-4 mt-xl-0 mx-auto">
                <div className="card card-cyan-bg dark-mode px-lg-4 py-2">
                  <div className="card-body text-center small">
                    <img
                      className="ms-auto"
                      height={60}
                      width={60}
                      src={failed}
                      alt="failed payment"
                    />
                    <h3 className="pt-3 pb-2 pb-lg-3">
                      Payment Processing Error
                    </h3>
                    <p className="text-start">
                      It appears that there was an issue during the payment
                      process, and your account has not been charged.To ensure
                      your payment is successfully processed, please take the
                      following actions:
                    </p> 
                    <ul className="text-start my-2">
                      <li>
                        Double-check the payment information provided, including
                        the card number, expiration date, and CVC code.
                      </li>
                      <li>
                        Ensure that your card is valid and has sufficient funds
                        available.
                      </li>
                      <li>
                        If the issue persists, you may consider trying an
                        alternative payment method.
                      </li>
                    </ul>
                    <p className="text-start pb-3">
                      If you need further assistance in resolving this matter or
                      have questions about the payment error, please reach out
                      to our support team at <u>support@opic3d.com</u>
                    </p>
                    <div className="d-flex justify-content-center mt-3 mb-3 position-relative">

                      <button
                      onClick={()=> navigateToPackages()}
                        className="gradient-btn" 
                        data="Back to payments"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AuthLayout>
    </>
  );
}

export default PaymentFailed;
