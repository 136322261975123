import React, { useEffect, useState, useRef } from "react";
import axios from '../../common/axiosInstance';
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import DashboardLayout from "../../components/layout/dashoboard-layout";
import { _userTypes, _limits, _security } from "../../common/constants";
import { Trash3 } from "react-bootstrap-icons";
import avatar from "../../assets/img/avatar.png";
import { checkTokenExpiry } from "../../common/utils";

const countryCodes = require("country-codes-list");
const myCountryCodesObject = countryCodes.customList(
  "countryCallingCode",
  "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
);

const options = Object.entries(myCountryCodesObject).map(([code, label]) => ({
  value: code,
  label: label,
}));

const geoRegions = [
  {
    value: 'NALA',
    label: 'North & Latin America'
  },
  {
    value: 'EMEA',
    label: 'Europe & Middle East'
  },
  {
    value: 'APAC',
    label: 'Asia Pacific'
  }
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    color: "#b4b4b4",
    boxShadow: "none",
    fontSize: "0.87rem",
    padding: "0 1.125rem",
    border: "1px solid #00ffff5c !important",
    height: "52px",
    borderRadius: "7px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.87rem",
    background: state.isSelected ? "gray" : "#002424",
    color: state.isSelected ? "black" : "white",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#b4b4b4",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#b4b4b4",
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    color: "#b4b4b4",
  }),
};

const notifySuccess = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

const notifyError = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

function Setting() {
  useEffect(() => {
    checkTokenExpiry();
    getUser();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));

  if (userInfo.userType === _userTypes.advertiser) {
    const exists = geoRegions.some((region) => region.value === 'all');
    if (!exists) {
      geoRegions.unshift({
        value: "all",
        label: "All Regions",
      });
    }
  }
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreiew] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [base64String, setBase64String] = useState("");
  const [name, setName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [profileImageSignedUrl, setProfileImageSignedUrl] = useState("");
  const [username, setUsername] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [existingUsername, setexistingUsername] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountry] = useState("");
  const [countryCodeIndex, setCountryCodeIndex] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [region, setRegion] = useState("");
  const [savedRegion, setSavedRegion] = useState("");
  const [aboveAge18, setAboveAge18] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry("+" + e.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleAboveAge18Change = (e) => {
    if (e.target.checked) {
      setAboveAge18("1");
    } else {
      setAboveAge18("0");
    }
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  const handleRegionChange = (e) => {
    setRegion(e.value);
  };

  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleBusinessType = (e) => {
    setBusinessType(e.target.value);
  };

  const getUser = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`/user?username=${userInfo.username}`)
        .then((response) => {
          let user = response.data.user;
          if (user) {
            setName(user.name);
            setProfileImage(user.profileImage);
            setProfileImageSignedUrl(user.profileImageSignedUrl);
            setUsername(user.username);
            setCompanyName(user.companyName);
            setBusinessType(user.businessType);
            setexistingUsername(user.username);
            setEmail(user.email);
            setCountry(user.countryCode);
            setPhone(user.phoneNumber);
            setRegion(user.geoRegion);
            setSavedRegion(user.geoRegion);
            setAboveAge18(user.aboveAge18);
            let index = options.findIndex(
              (x) => x.value === user.phoneCode.split("+")[1]
            );
            setCountryCodeIndex(index);
          }
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const updateUser = async () => {
    setLoading(true);

    const requestModal = {
      name,
      username,
      isFileUploaded,
      base64String: imagePreview,
      existingUsername,
      companyName,
      businessType,
      region,
      savedRegion,
      email,
      countryCode,
      phoneNumber,
      aboveAge18,
      userType: userInfo.userType,
    };

    try {
      const response = await axios.post('/user', requestModal)
        .then((response) => {
          const res = response.data;
          if (res) {
            notifySuccess(res.message);
            setSelectedFile(null);
            setIsFileUploaded(false);
            getUser();
            setLoading(false);
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check File Size
    const maxSize = _limits.imageSize * 1024 * 1024;
    if (file.size > maxSize) {
      notifyError(`File size exceeds the maximum limit (${_limits.imageSize} MB).`)
      return;
    }

    // Check file extension
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'webp'];
    const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      notifyError(`Invalid file extension. Allowed extensions are .jpg, .jpeg, .webp and .png`);
      return;
    }

    // Set the selected file
    setSelectedFile(file);
    setIsFileUploaded(true);

    // Read the contents of the image file and set the src attribute
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreiew(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteImageClick = () => {
    setSelectedFile(null);
    setIsFileUploaded(false);
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <DashboardLayout>
          <section className="page-wrapper">
            <div className="d-flex flex-column position-relative h-100">
              <div className="container mt-auto">
                <div className="row align-items-center">
                  <div className="col-xl-6 mb-5 mb-lg-0 mt-4 mt-xl-0 mx-auto">
                    <div className="auth-card">
                      <div className="cursor-pointer d-flex justify-content-end mb-3 small">
                        <a href="/blocked-users" className="cursor-pointer">Manage Blocked Users</a>
                      </div>
                      <h3 className="text-uppercase text-center h5 pt-1 pb-3 text-cyan">
                        Edit Account Information
                      </h3>
                      <form onSubmit={updateUser}>
                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                        <div className="profile-picture">
                          <div className="profile-picture-outer">
                            {
                              isFileUploaded ? (
                                <>
                                  <img src={imagePreview} />
                                  <div className="cross-icon" onClick={handleDeleteImageClick}>
                                    <Trash3 className="cursor-pointer" />
                                  </div>
                                </>
                              ) : (
                                <img src={profileImage && profileImage !== "" ? profileImageSignedUrl : avatar} />
                              )
                            }
                          </div>
                          <div className="">
                            <button
                              type="button"
                              className="btn-sm gradient-btn px-4"
                              data="Change Picture"
                              onClick={handleFileUploadClick}
                            ></button>
                          </div>
                        </div>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
                          <div className="col mb-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Your name"
                              value={name}
                              onChange={handleNameChange}
                              required
                            />
                          </div>
                          <div className="col mb-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        {userInfo.userType === _userTypes.advertiser ? (
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Company Name"
                                value={companyName}
                                onChange={handleCompanyName}
                                required
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Business Type"
                                value={businessType}
                                onChange={handleBusinessType}
                                required
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row row-cols-1 row-cols-md-1 row-cols-lg-1">
                          <div className="col-12 mb-3">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email address"
                              value={email}
                              onChange={handleEmailChange}
                              required
                            />
                          </div>
                        </div>
                        {userInfo.userType === _userTypes.advertiser ? (
                          <div className="row">
                            <div className="col-12 mb-3">
                              <Select
                                options={geoRegions}
                                isSearchable={true}
                                styles={customStyles}
                                placeholder="Geographic Region"
                                onChange={handleRegionChange}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
                          <div className="col mb-3 theme-select">
                            {countryCodeIndex !== "" ? (
                              <Select
                                options={options}
                                isSearchable={true}
                                styles={customStyles}
                                value={options.value}
                                defaultValue={options[countryCodeIndex]}
                                onChange={handleCountryChange}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col mb-3">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Phone"
                              value={phoneNumber}
                              onChange={handlePhoneChange}
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="age"
                              checked={aboveAge18}
                              onChange={handleAboveAge18Change}
                            />
                            <label
                              className="form-check-label ms-1 small"
                              htmlFor="age"
                            >
                              Are you above 18 years of age?
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="align-items-center d-flex form-check gap-2 p-0 mb-3 small">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="terms"
                              checked={termsChecked}
                              onChange={handleTermsChange}
                            />
                            <label
                              className="form-check-label ms-1 small"
                              htmlFor="terms"
                            >
                              I agree to
                              <Link
                                target="_blank"
                                to="https://opic3d.com/terms-and-conditions/"
                                className="ms-1"
                              >
                                Terms &amp; Conditions
                              </Link>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            type="submit"
                            className={`gradient-btn ${termsChecked || formDisabled ? "" : "disabled"
                              }`}
                            disabled={!termsChecked && !formDisabled}
                            data="Update Profile"
                          ></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Setting;
