import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import { KeyFill, Eye, EyeSlash } from "react-bootstrap-icons";
import { checkTokenExpiry } from "../../common/utils";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import mailImg from "../../assets/img/mail.png";
import PasswordStrengthBar from "react-password-strength-bar";
import { _security } from "../../common/constants";

function ChangePass() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
    checkTokenExpiry();
  }, []);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const passwordStrengthBarColor = [
    "rgba(0, 255, 255, 0.3607843137)",
    "#ef4836",
    "#f6b44d",
    "#2b90ef",
    "#25c281",
  ];

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const toggleRepeatShowPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handlePasswordStrengthStateChange = (score, feedback) => {
    if (score < 2) {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (password.trim() !== repeatPassword.trim()) {
      notifyError("Password doesn't matched");
      return;
    }
    setLoading(true);

    const requestModal = {
      password: password,
      repeatPassword: repeatPassword,
      username: userInfo.username,
      otp: localStorage.getItem("otp"),
    };

    try {
      const response = await axios.post('/changepassword', requestModal)
        .then((response) => {
          const res = response.data;
          setLoading(false);
          notifySuccess(res.message);
          localStorage.clear();

          setTimeout(() => {
            navigate("/launch");
          }, 2000);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card">
              <img
                className="m-auto d-block"
                height={60}
                width={60}
                src={mailImg}
                alt="mail"
              />
              <h3 className="text-uppercase text-center h5 pt-1 pb-3 text-cyan">
                Change Password
              </h3>
              <form onSubmit={changePassword}>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    required=""
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <KeyFill className="icon" />
                  {
                    showPassword ? (
                      <EyeSlash onClick={toggleShowPassword} className="eye-slash" />
                    ) : (
                      <Eye onClick={toggleShowPassword} className="eye-icon" />
                    )
                  }
                  <PasswordStrengthBar
                    password={password}
                    barColors={passwordStrengthBarColor}
                    onChangeScore={handlePasswordStrengthStateChange}
                  />
                </div>
                <div className="input-icon mb-3">
                  <input
                    className="form-control"
                    type={showRepeatPassword ? 'text' : 'password'}
                    placeholder="Repeat Password"
                    required=""
                    value={repeatPassword}
                    onChange={handleRepeatPasswordChange}
                  />
                  <KeyFill className="icon" />
                  {
                    showRepeatPassword ? (
                      <EyeSlash onClick={toggleRepeatShowPassword} className="eye-slash" />
                    ) : (
                      <Eye onClick={toggleRepeatShowPassword} className="eye-icon" />
                    )
                  }
                  <PasswordStrengthBar
                    password={repeatPassword}
                    barColors={passwordStrengthBarColor}
                    onChangeScore={handlePasswordStrengthStateChange}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className={`gradient-btn w-100" ${formDisabled ? "disabled" : ""}`}
                    disabled={formDisabled}
                    data="Update"
                  ></button>
                </div>
              </form>
            </div>
          </section>
          <ToastContainer />
        </AuthLayout>
      )}
    </>
  );
}
export default ChangePass;
