import axios from 'axios';
import { _security } from "../common/constants";

const instance = axios.create({
  baseURL: `${_security.apiBaseUrl}/${_security.apiSuffix}`,
});

// Add a request interceptor to include the JWT token in the headers
instance.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['x-api-key'] = _security.apiKey;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;