import React, { useEffect, useState } from "react";
import axios from '../../common/axiosInstance';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "../../components/layout/auth-layout";
import animationData from "../../components/lottie/animation.json";
import Lottie from "lottie-react";
import { ArrowRightShort } from "react-bootstrap-icons";
import { _security } from "../../common/constants";

function ForgetPass() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post('/forgot',{username})
        .then((response) => {
          const res = response.data;
          localStorage.setItem("token", res.token);
          localStorage.setItem("user", JSON.stringify(res.user));
          navigate("/forgotOTP");
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 403) {
        notifyError(error.response.data.message);
      }
    }
  };
  return (
    <>
      {loading ? (
        <div className="lottie-wrapper page-wrapper">
          <Lottie animationData={animationData} loop={true} />
        </div>
      ) : (
        <AuthLayout>
          <section className="page-wrapper mt-4">
            <div className="auth-card">
              <h3 className="text-uppercase text-center h4 pt-1 pb-3 text-cyan">
                Forgot your password?
              </h3>
              <p className="pb-2 text-gray">
                Change your password in three easy steps. This helps to keep
                your new password secure.
              </p>
              <ul className="list-unstyled mb-4 text-gray">
                <li className="d-flex mb-2">
                  <ArrowRightShort className="flex-shrink-0 h4 mb-0" /> Fill in your username
                  or email below.
                </li>
                <li className="d-flex mb-2">
                  <ArrowRightShort className="flex-shrink-0 h4 mb-0" /> We'll email you a
                  temporary code.
                </li>
                <li className="d-flex mb-2">
                  <ArrowRightShort className="flex-shrink-0 h4 mb-0" /> Use the code to change
                  your password on our secure website.
                </li>
              </ul>
              <form onSubmit={forgotPassword}>
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Username or Email"
                  required=""
                  value={username}
                  onChange={handleUsernameChange}
                />
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    class="gradient-btn w-100"
                    data="Get new password"
                  ></button>
                </div>
              </form>
            </div>
          </section>
        </AuthLayout>
      )}
    </>
  );
}
export default ForgetPass;
